import React, { useEffect } from "react"
import styles from "./Hakkimizda.module.scss"
import people from "../../assets/images/people.jpg"

const Hakkimizda = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  return (
    <section className={styles.hakkimizda}>
      <h2>Hakkımızda</h2>
      <p>
        Firmamız, sizlere alanında yüksek kaliteli hizmetler sunmaktadır. Perde
        sektörünü yakından takip eden firmamız 2011 yılında kurulmuştur. Başta
        Batıkent ve Yenimahalle olmak üzere Ankara’nın her yerinden
        hizmetlerimizden faydalanabilirsiniz. Perde sektöründe yenilikleri
        yakından takip eden Çağlayan Perde, geniş ürün yelpazesi ile konutlardan
        ofislere kadar size birçok çeşit sunmaktadır. Kısacası, perdelerimizi
        görmeden, hizmetimiz hakkında bilgi almadan karar vermemenizi öneririz.
        Perde sektöründeki tecrübelerimizle siz hayal edin biz gerçekleştirelim.
      </p>

      <div className={styles.midContainer}>
      <img src={people} alt="" />

      </div>

      <div className={styles.bottomSection}>
        
        <div className={styles.topsentence}>
          <span>1</span>
          <h3>Yerinde Montaj</h3>
        </div>
        <p>
          Çağlayan perde firması olarak ürünlerimizin kalitesinin yanısıra
          müşterilerimize sunduğumuz hizmetlerin kalitesini gördüğünüzde bizi
          tercih ettiğiniz için pişman olmayacaksınız. Her türlü perde için
          montaj aşamasında sizleri yalnız bırakmayarak farkımızı burada da
          hissettiriyoruz.
          <br /> <br />
          Çağlayan Perde ekibi olarak kalite anlayışımız doğrultusunda başta
          Batıkent ve Yenimahalle olmak üzere perde montajınızı yapmaktayız.
          Temiz ve özenli çalışma sistemimizle müşterilerimizin memnuniyetini
          önemsiyoruz. Söz konusu perde montajı olduğunda başka adres aramayın.
          Çağlayan Perde bir telefon kadar uzağınızda.
        </p><br /><br />

        <div className={styles.topsentence}>
          <span>2</span>
          <h3>Perde Tamiri</h3>
        </div>
        <p>
        Firmamız Çağlayan Perde, müşterilerine kaliteli bir hizmet sunmak adına perde tamiri işlerinizde de sizlere yardımcı olmaktadır. Her türlü perde tamirinde verdiğimiz hizmetle sizleri memnun etmekten gurur duyarız. Perde tamiri söz konusu olduğunda bize ulaşmanız yeterli olacaktır.
        Çağlayan perde ekibi olarak başta Batıkent ve Yenimahalle olmak üzere perde tamiri işinde de temiz ve özenli bir iş yürütmekteyiz. Perde tamiri gerekli olduğunda başka adres aramayın! Bize ulaşın ki sorununuzu çözelim. Çağlayan Perde bir telefon kadar uzağınızda.
        </p><br /><br />

        <div className={styles.topsentence}>
          <span>3</span>
          <h3>Perde Temizliği</h3>
        </div>
        <p>
        Çağlayan Perde ve ekibi siz müşterilerimizin rahatlığı için perde temizliği ile de ilgilenir. Perdeleriniz kirlendi diye dert etmeyin. Yalnızca bir telefon kadar uzağınızdayız. Perde temizliği konusu her zaman perde modeli seçimlerinizi de etkilemiştir. artık buna bir son veriyoruz ve perde temizliğinizle biz Çağlayan Perde firması olarak ilgileniyoruz. Perdelerinizi seçerken artık nasıl temizleyeceğim diye düşünmenize gerek kalmadı. Çağlayan Perde'yi arayın biz halledelim!
        Çağlayan Perde ekibi olarak başta Batıkent ve Yenimahalle olmak üzere perde temizliği hizmeti vermekteyiz. Kaliteli bir hizmet için bize ulaşın.
        </p><br /><br />
      </div>
    </section>
  )
}

export default Hakkimizda
