import React from "react"
import styles from "./Whatsapp.module.scss"
import { BsWhatsapp } from "react-icons/bs"

const Whatsapp = () => {
  return (
    <a className={styles.whatsapp} href="https://wa.me/905052575886?text=Merhaba,%0aBilgi%20alabilir%20miyim%3F" target="_blank" rel="noopener noreferrer" title='whatsapp' >
      <BsWhatsapp alt='whatsapp' />
    </a>
  )
}

export default Whatsapp
