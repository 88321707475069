import React from "react"
import styles from "./Telephone.module.scss"
import { BiPhoneCall } from "react-icons/bi"

const Telephone = () => {
  return (
    <a className={styles.telephone} href="tel:+905052575886" title="Telefon">
      <BiPhoneCall alt="telefon" />
      <span className={styles.pulse}></span>
    </a>
  )
}

export default Telephone
