import React, { useState, useRef, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import styles from "./Header.module.scss"
import logo from "../../assets/images/logo.jpg"
import Hamburger from '../hamburger/Hamburger';

const Header = () => {

    
  const [isActiveDestek, setIsActiveDestek] = useState(false)

  
  const [showMenu, setShowMenu] = useState(false);

  const navRef = useRef(null);
  const hamburgerRef = useRef(null);

  useEffect(() => {
    // showMenu && toggleMenu();
    document.addEventListener('keydown', keyPress);
    return () => {
      document.removeEventListener('keydown', keyPress);
    };
  });

  const clickOutside = (e) => {
    if (!navRef.current) return
    if (!showMenu || navRef.current.contains(e.target) || e.target === hamburgerRef.current) return;
    hideMenu();
  }

  const toggleMenu = () => {
    // document.body.classList.add(styles.active);
    // navRef.current.style.maxHeight = navRef.current.scrollHeight+500 + 'px';
    setShowMenu(!showMenu);
    // console.log("toggle")
  }

  const hideMenu = () => {
    // document.body.classList.remove(styles.active);
    // navRef.current && (navRef.current.style.maxHeight = null);
    setShowMenu(false)
    setIsActiveDestek(false)
    // console.log("hideMenu")
    setDesteklerClicked(false)
  }

  const toggleDestek = () => { 
    setIsActiveDestek(!isActiveDestek); 
    // document.querySelector(".desteklerLine").classList.toggle("desteklerLineActive")
    setDesteklerClicked(true)
  }


  const keyPress = (e) => {
    showMenu && e.keyCode === 27 && hideMenu()
  }

  const logoHandler = () => {
    // setNav("home")
    // if(nav === "home") {
        // document.getElementById('paraxContainer').scrollTo({top: 0})
    // }
    window.scrollTo({top: 0})
  }

  const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "")

  const [desteklerClicked, setDesteklerClicked] = useState(false)

  

  return (
    <header>        
        {showMenu && <div className={styles.fullscreenwrapper} onClick={hideMenu} />}
        {isActiveDestek && !showMenu && <div className={styles.fullscreenwrapper} onClick={hideMenu} />}
        <div className={styles.left}>
            <Link to="/" className={styles.logoContainer} onClick={logoHandler}>
                <img src={logo} alt="Kosgeb Logo" />
            </Link>
        </div>
            
        <div className={styles.right}>
            
            
            <span className={!showMenu ? `${styles["menu-icon"]}` : `${styles["menu-icon"]} ${styles.active}`} onClick={toggleMenu} >
                    <Hamburger className={!showMenu ? `${styles["menu-icon"]}` : `${styles["menu-icon"]} ${styles.active}`} showMenu={showMenu} />
            </span>

            <nav ref={navRef} className={showMenu ? "" : `${styles.active}`}>        
                

                <ul className={showMenu ? `${styles["show-ddlist"]}` : `${styles["hide-ddlist"]}`} >

                    <li>
                        <NavLink to="/"  className={activeLink} onClick={hideMenu}><span/>Anasayfa</NavLink>
                    </li>

                    <li className={isActiveDestek ? styles.desteklerLine : ""} onClick={toggleDestek}>
                        <NavLink className={desteklerClicked ? `${styles.active}` : ""}><span/>Hizmetlerimiz</NavLink>
                        
                        {isActiveDestek &&
                            <ul className={styles.submenu}>
                                <NavLink onClick={hideMenu} to="/girisimcilik" className={activeLink}><span/>Perde Satışı ve Montajı</NavLink>

                                <NavLink onClick={hideMenu} to="/kredi"  className={activeLink}><span/>Korniş Montajı</NavLink>

                                <NavLink onClick={hideMenu} to="/arge" className={activeLink} ><span/>Perde Bakım ve Temizliği</NavLink>

                            </ul>
                        }
                    </li>
                    
                    <li>
                        <NavLink to="/hakkimizda" onClick={hideMenu}  className={activeLink} ><span></span>Hakkımızda</NavLink>
                    </li>

                    <li>
                        <NavLink to="/blog" onClick={hideMenu}  className={activeLink} ><span></span>?</NavLink>
                    </li>

                    <li>
                    {/* Whatsapp'a mesaj göndermek için */}
                    <a href="https://wa.me/905396922615?text=Merhaba,%0aDestekler%20hakkında%20bilgi%20alabilir%20miyim%3F" target="_blank" rel="noopener noreferrer" onClick={hideMenu}><span/>İletişim</a>
                    </li>

                </ul>
                        
            </nav>
        </div>
    </header>
  );
}

export default Header;
