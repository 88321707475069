import React, { useEffect } from "react"
import styles from "./WebTasarimNedir.module.scss"
import { Link, useLocation } from "react-router-dom"
import webdesignFoto from "../../../../assets/images/mobil-uyumlu-web-tasarim.png"
import haritaTurkey from "../../../../assets/images/haritaTurkey.png"
import {
  townsEnglish,
  cardsManual
} from "../../../../assets/data/TownsData"
import { Seo } from "../../../../components/seo/Seo"
import Footer from "../../../../components/footer/Footer"
import { Heading, TableOfContent } from "../../../../components/customhooks/useHeadings"
import Card from "../../../../components/card/Card"

const WebTasarimNedir = ({title, imgName}) => {
  const allCards = cardsManual

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  // eslint-disable-next-line no-extend-native
  Array.prototype.shuffle = function() {
    let m = this.length, i;
    while (m) {
      i = (Math.random() * m--) >>> 0;
      [this[m], this[i]] = [this[i], this[m]]
    }
    return this;
  }

  const CardComp = ({imgName, title, link }) => (
    <Card key={link} cardClass={`${styles.card} webDesignCard`}>
        <div className={styles.imgContainer} key={title}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
        </div>
        <h2><strong>{title}</strong></h2>
        {/* <Link to={`${link}`}>İncele</Link> */}
        <Link to={`${link}`} state={{imageUrl: `/images/${imgName}`, title: title}} onClick={()=>window.scrollTo({ top: 0, behavior: "smooth" })}>Devamı ⯈</Link>
    </Card>
)


const CardsManualfromData = cardsManual.filter((card)=> card.title !== title).shuffle().slice(0, 6).map((card, i)=>(
    <CardComp key={card.key} imgName={card.imgName} title={card.title} link={card.link} />
))

  return (
    <div className={styles.container}>
      <Seo
        title={`${title} | Web Tasarım | Bubi Yazılım `}
        description="Siz hayal edin, biz yapalım.."
        keywords="En iyi web tasarım siteleri, en iyi web site firması, "
      />
      <h1>{title}</h1>
      <section className={styles.splitSection}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
          </div>
          <p>
            “{title}" sorusuna tek bir kısa cevap verecek olursak; Web tasarımı;
            görseller ve metinlerden oluşan web sayfası içeriklerinin web sitesi
            ziyaretçilerine bir düzen içerisinde sunulmasıdır. Web sitesi
            tasarımı, web tasarım projesi veya web sitesi yapımı gibi tanımlar
            da, web tasarımı ile eş anlamda kullanılır. Web tasarımı yapabilmek
            için öncelikle bir projeye ihtiyaç vardır. Firma, şahıs, marka veya
            ürün projenin esas konusunu oluşturur. Örneğin; doktor veya diş
            hekimi web sitesi, firma web sitesi, otel web sitesi, sağlık merkezi
            veya hastane web sitesi bir web tasarım projesidir. Buradan da
            anlaşıldığı gibi web tasarım kişi, kurum veya kuruluşları tanıtmak
            amacıyla yapılan, web sitesi sayfalarını kodlama, düzenleme ve
            internet ortamında yayınlama çalışmalarının tümüdür. Bu çalışmaları
            yapan uzman kişilere web tasarımcı, web tasarımcısı veya web master
            denir.
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>{" "}
            olarak web tasarımı konusunda tecrübemizi, işletmelerin dijital
            dünyada yer alması için özveriyle aktarıyoruz.
          </p>

          <TableOfContent />

          <Heading as="h2">Web Tasarım Nasıl Yapılır?</Heading>
          <p>
            Bir web sitesi yayınlanmadan önce birçok aşamadan geçer. Projenin
            Belirlenmesi Kurumsal Kimlik Çalışması Grafik Tasarım Çalışması Web
            Sayfalarının Kodlaması İçerik Yönetim Sistemi Entegrasyonu Web
            Sitesinin Test Süreci Web Sitesinin Yayınlanması Bu aşamalar da
            kendi içerisinde daha detaylı çalışmaları barındırır.
          </p>

          <Heading as="h3">Projenin Belirlenmesi</Heading>
          <p>
            Proje belirlenirken ihtiyaçlar göz önünde bulundurulur. Web
            sitesinin yapılış amacı, kişi yada kurumu tanıtmak olabildiği gibi
            firmanın ticari faaliyetlerini ön plana çıkarmak da olabilir. Bu
            noktada kapsamlı rakip firma analizleri yapılır. Proje belirlenirken
            web sitesinin yapılış amacı ve web sayfası içeriklerinin neler
            olacağı netlik kazanır.
          </p>
          <Heading as="h3">Kurumsal Kimlik Çalışması</Heading>
          <p>
            Kurumsal kimlik çalışması, web tasarım projesi hayata geçmeden önce
            tamamlanması gereken çalışmalardır. Örneğin; firma logosu kurumsal
            kimlik çalışmasının bir parçasıdır. Web sitesinde kullanılan renkler
            firmanın kurumsal renkleri ile doğru orantılı olmalıdır. Logo, renk,
            slogan, vb. kurumsal kimlik öğeleri web tasarımında kullanılan en
            önemli unsurlardır.
          </p>
          <Heading as="h3">Grafik Tasarım Çalışması</Heading>
          <p>
            Grafik tasarım çalışmalarında, kurumsal kimlik çalışmaları esas
            alınır. Adobe Photoshop gibi piksel tabanlı resim ve fotoğraf
            düzenleme programları kullanılarak web sayfalarının görsel tasarımı
            yapılır. Web sayfalarında kullanılacak resim, fotoğraf, grafik gibi
            görseller özenle hazırlanarak uygun ve doğru yerlerde
            konumlandırılır.
          </p>
          <Heading as="h3">Web Sayfası Kodlaması</Heading>
          <p>
            Web sayfalarının kodlaması sürecinde HTML, CSS, JavaScript, SQL,
            PHP, Python ve ASP gibi çeşitli programlama dilleri kullanılır.
            Google Chrome, Mozilla Firefox, Opera veya Internet Explorer gibi
            internet tarayıcılarının web sitelerini görüntüleyebilmeleri için
            web sayfalarının bu dillerde kodlanması gerekir. Grafik tasarımı,
            web sayfalarının kodlaması ve web sayfası içeriklerinin
            oluşturulması sonucunda web sitesinin genel yapısı ve görünümü
            ortaya çıkar.
          </p>
          <Heading as="h3">İçerik Yönetim Sistemi (CMS) Entegrasyonu</Heading>
          <p>
            İçerik Yönetim Sistemi (CMS), web sitesi sahibinin web sayfalarına
            fotoğraf, resim, tablo, grafik, video, metin, vb. içerikleri ekleyip
            çıkarmasını sağlayan yazılımlardır. CMS yazılımları, web sitesi
            sahibinin web tasarımcıya bağlı kalmadan site içeriğini
            güncelleyebilmesini sağlar. Bununla birlikte günümüzde, CMS
            yazılımları sadece web sitelerinin güncellenmesi için
            kullanılmamaktadır. Bir çok web tasarım hizmeti veren firma veya
            freelance tasarımcılar, web tasarım çalışmalarında CMS
            yazılımlarından faydalanarak hizmetlerini sunabilmektedir. CMS
            yazılımları, CMS yazılımlarının özellikleri, kullanım alanları ve
            faydaları oldukça kapsamlı bir konu olmakla beraber ayrı bir başlık
            altında incelenebilir.
          </p>
          <Heading as="h3">Web Sitesinin Test Süreci</Heading>
          <p>
            Web tasarım çalışmalarının doğru şekilde yapılıp yapılmadığını
            gösteren bu süreçte, eksik veya fazla yapılan çalışmalar belirlenir.
            Web sitesinde bulunması gereken ancak gözden kaçan detaylar
            olabilir. Bu bazen teknik bir konu bazen de görsel veya metin odaklı
            içerikle ilgilidir. Test aşamalarında web sayfalarının görsel
            durumu, mobil cihazlardaki görünümü, sayfa açılış hızları, vb.
            konular üzerinde gerekli kontroller gerçekleştirilerek, web
            sitesinin çalışma performansı optimal seviyeye getirilir.
          </p>
          <Heading as="h3">Web Sitesinin Yayınlanması</Heading>
          <p>
            Elbette web tasarım aşamaları içerisinde ifade edilebilecek farklı
            konular da vardır. Ancak en basit anlatımla bir web tasarım projesi
            bu süreçlerden geçer. Kapsamlı ve detaylı çalışmalar sonucunda web
            sitesi yayına hazırdır. Web sitesinin yayınlanabilmesi için domain
            olarak adlandırılan alan adına ve web sitesi sayfalarının
            barındırılacağı bir hosting hizmetine ihtiyaç vardır. Alan adı ve
            hosting hizmeti alındıktan sonra web sitesi yayınlanır. Web sitesi
            yayına alındıktan sonra arama motorlarına her bir web sayfasının
            tanımlandığı site haritasını göndermek gerekir. Google Arama Motoru
            günümüzde en çok kullanılan arama motorudur. Web sitesi yayına
            alındıktan sonra web sitesine ait site haritası Google’a gönderilir.
            Web sitesi indekslenerek Google arama sonuçlarında ziyaretçiler
            tarafından görüntülenir.
          </p>
          
          <div className={styles.imgContainer}>
            <img src={`/images/question.jpg`} alt={imgName} loading="lazy" />
          </div>
          <Heading as="h2">Web Tasarımı Neden Yapılır?</Heading>
          <p>
            Web tasarımının temel amacı, web sitesi ziyaretçilerine ve web
            sitesinin sahibine karşılıklı fayda sağlayacak bir platformun
            oluşturulmasıdır. Karşılıklı fayda sağlayan bir proje amacına
            ulaşmıştır. Ürününü tanıtmak isteyen bir firma ile ürünü tanıyan,
            beğenen ve satın almak isteyen bir ziyaretçi buna güzel bir
            örnektir.
            <br />
            Web tasarımı çok çeşitli amaçlar doğrultusunda projelendirilir.
            Bunlar şu şekilde sıralanmaktadır:
            <br />
            &#10004; &nbsp; Kurumsal tanıtım amaçlı web siteleri
            <br />
            &#10004; &nbsp; Ticari ürün satış ve tanıtım web siteleri
            <br />
            &#10004; &nbsp; Kişisel blog web siteleri
            <br />
            &#10004; &nbsp; Haber web siteleri
            <br />
            &#10004; &nbsp; Sosyal medya web siteleri
            <br />
            &#10004; &nbsp; Online satış veya E-ticaret web siteleri
            <br />
            <br />
            &nbsp;“Web tasarım projeleri ister ticari amaçlı isterse de tanıtım
            odaklı olsun; en nihayetinde kurumsal kimliğinize değer katar.”
            <br />
            <br />
            Teknolojinin hızla geliştiği, ticaret dinamiklerinin tamamen
            değiştiği, tanıtım faaliyetlerinin ve aktivitelerinin çeşitlilik
            kazandığı bu dönemde web tasarım çalışmaları da büyük bir ivme
            yakalamıştır. Web tasarım çalışmalarının planlama ve geliştirme
            süreçleri yeni bir boyut kazanmıştır. Günümüzde, firmaların
            potansiyellerini arttırmak, büyümek ve daha çok kazanç elde etmek
            için teknolojiye duydukları ihtiyaç her geçen gün daha da
            artmaktadır. Çoğu zaman <strong><Link to={"/web-sitesi-fiyatlari"}>web tasarım fiyatlarından</Link></strong> daha ziyade ortaya
            çıkacak projenin kalitesi göz önünde bulundurulur. Kimileri yüksek
            teknolojiye ayak uydurarak ilerlerken kimileri bu trene henüz
            binmemişlerdir. Google, Facebook, Instagram, Twitter, Youtube gibi
            web dünyasının liderleri, sayamayacağımız kadar özellikler içeren
            hizmetleriyle web tasarım ve web yazılım sektörlerine de büyük katkı
            sağlamıştır.
          </p>
          
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/${imgName}) no-repeat center center fixed`,
              backgroundSize:"contain"
            }}
          ></div>
          <div
            className={styles.paraxImg}
            style={{
              background: `url(${webdesignFoto}) no-repeat center center fixed`,
              backgroundSize:"contain"
            }}
          ></div>

          
        </div>

        <div className={styles.right}>
          <h3>Son Paylaşımlar</h3>

          <Link to={allCards[0].link} target="_blank">
            <strong>{allCards[0].title}</strong>
          </Link>
          <Link to={allCards[1].link} target="_blank">
            <strong>{allCards[1].title}</strong>
          </Link>
          <Link to={allCards[2].link} target="_blank">
            <strong>{allCards[2].title}</strong>
          </Link>
          <Link to={allCards[3].link} target="_blank">
            <strong>{allCards[3].title}</strong>
          </Link>
          {/* <Link to="/ankara-web-tasarim-firmalari">
            Profesyonel Web Tasarım{" "}
            {townsOfInterest[0].charAt(0).toUpperCase() +
              townsOfInterest[0].slice(1)}
          </Link> */}
        </div>
      </section>

      <section className={styles.benzerYazilar}>
        <h3>İlgili Makaleler</h3>
        <div className={styles.cardsContainer}>
          {CardsManualfromData}
        </div>
      </section>

      <section className={styles.bottomTags}>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web sitesi</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web sitesi tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web sitesi fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web site</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX kurumsal web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX profesyonel web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web yazılım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX web tasarım fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım XXX</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>XXX website</strong>
        </Link>
      </section>

      {/* <Footer /> */}
    </div>
  )
}

export default WebTasarimNedir
