import React, { useEffect, useRef, useState } from 'react'
import { BsArrowUpShort } from 'react-icons/bs'
import Telephone from '../telephone/Telephone'
import Whatsapp from '../whatsapp/Whatsapp'
import styles from "./ScrollTopButton.module.scss"

const ScrollTopButton = () => {

    const scrollTopRef = useRef(null)
    const [scrollUp, setScrollUp] = useState(false)

    const scrollToTop = () => {        
      window.scrollTo({top:0, behavior: "smooth"})  
    }

    const [showTelephone, setShowTelephone] = useState(true)

    let oldScroll = 0
    useEffect(() => {
      document.addEventListener("scroll", handleScroll)
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    }, [])
    
    const handleScroll = () =>  {
        // print "false" if direction is down and "true" if up
        // console.log(this.oldScroll > this.scrollY);
        // console.log(window.scrollY)
        if (scrollTopRef.current) {
            // when slider is embedded --> (window.scrollY > vw(50)) is added
            if(oldScroll > window.scrollY+5  && (window.innerHeight + window.scrollY) < document.documentElement.scrollHeight ) {
                if(window.scrollY===0) {
                setScrollUp(false)                
                setShowTelephone(false)
                scrollTopRef.current.style.transform = "scale(0)"
                } else {
                // console.log("true")
                setScrollUp(true)
                scrollTopRef.current.style.transform = "scale(1)"
                }
            } else if (oldScroll < window.scrollY-5 || window.scrollY === 0 ) {
                setScrollUp(false)
                scrollTopRef.current.style.transform = "scale(0)"
            }
            oldScroll = window.scrollY
        }
        
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight || window.scrollY === 0) {
          // you're at the bottom of the page
          setShowTelephone(false)
        } else {
          setShowTelephone(true)
        }
      }


  return (
    <>
    <div className={styles.scrollTop} onClick={scrollToTop} ref={scrollTopRef} style={{}} title='Başa Dön' >
        <BsArrowUpShort />

        
    </div>
    { showTelephone && 
          <>
            <Telephone />
            <Whatsapp />
          </>
        }
    </>
  )
}

export default ScrollTopButton