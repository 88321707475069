import React, { useEffect, useState } from 'react'
import styles from "./Pagination.module.scss"

const Pagination = ({currentPage, setCurrentPage, productsPerPage, totalNumberOfProducts}) => {

  const pageNumbers = []
  // const totalPages = totalNumberOfProducts / productsPerPage
  
  // Limit the page numbers shown:
  const [pageNumberLimit] = useState(5)
  const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5)
  const [minPageNumberLimit, setMinPageNumberLimit] = useState(0)

  // Paginate
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber)
  }
  // Go to next page
  const paginateNext = () => {
    setCurrentPage(currentPage+1)
    // Show next set of page numbers:
    if (currentPage + 1 > maxPageNumberLimit) {
      setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit)
    }
  }

  // Go to prev page
  const paginatePrev = () => {
    setCurrentPage(currentPage-1)
    // Show prev set of page numbers:
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
      setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
    }
  }

  for(let i=1; i <= Math.ceil(totalNumberOfProducts / productsPerPage); i++) {
    pageNumbers.push(i)
  }
  
  useEffect(()=>{
    setTimeout(() => {
      window.scrollTo({top:0, behavior: "smooth"})      
    }, 100);
  }, [currentPage])


  return (
    <ul className={styles.pagination}>
      <li onClick={paginatePrev} className={currentPage === pageNumbers[0] ? `${styles.hidden}` : null}>Önceki</li>

      {pageNumbers.map((pageNumber) => {
        if(pageNumber < maxPageNumberLimit+1 && pageNumber > minPageNumberLimit) {
          return <li key={pageNumber} onClick={() => paginate(pageNumber)} 
                    className={currentPage === pageNumber ? `${styles.active}` : null}>{pageNumber}
            </li>
        } 
        else return null
      })}
      <li onClick={paginateNext} className={currentPage === pageNumbers[pageNumbers.length-1] ? `${styles.hidden}` : null}>Sonraki</li>
      {/* <p><b className={styles.page}>{`Sayfa ${currentPage}`}</b><span>{` / `}</span><b>{`${Math.ceil(totalPages)}`}</b></p> */}
    </ul>
  )
}

export default Pagination