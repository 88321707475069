import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { towns, townsEnglish } from "./assets/data/TownsData";
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import Telephone from './components/telephone/Telephone';
import Whatsapp from './components/whatsapp/Whatsapp';
import BlogMainpage from './pages/blogmainpage/BlogMainpage';
import Eniyiwebtasarimsiteleri from './pages/blogpages/eniyiwebtasarimsiteleri/Eniyiwebtasarimsiteleri';
import SeoNedir from './pages/blogpages/seonedir/SeoNedir';
import WebsitesiFiyatlari from './pages/blogpages/websitesifiyatlari/WebsitesiFiyatlari';
import WebTasarimNedir from './pages/blogpages/webtasarimnedir/webtasarim/WebTasarimNedir';
import Hakkimizda from './pages/hakkimizda/Hakkimizda';
import Home from './pages/home/Home';
import WebTasarim from './pages/webtasarim/WebTasarim1';


function App() {
  
  

  // const townsAnkara = ["ankara","akyurt","ayaş","altındağ","bala","batıkent","çamlıdere","beypazarı","çankaya","elmadağ","eryaman","çubuk","etimesgut","gölbaşı","evren","haymana","güdül","kahramankazan","keçiören","kızılcahamam","kalecik","nallıhan","mamak","polatlı","sincan","pursaklar","şereflikoçhisar","yenimahalle"]
  // const townsIstanbul = ["istanbul", "adalar","arnavutköy","ataşehir","avcılar","bağcılar","bahçelievler","bakırköy","başakşehir","bayrampaşa","beşiktaş","beykoz","beylikdüzü","beyoğlu","büyükçekmece","çatalca","çekmeköy","esenler","esenyurt","eyüpsultan","fatih","gaziosmanpaşa","güngören","kadıköy","kağıthane","kartal","küçükçekmece","maltepe","pendik","sancaktepe","sarıyer","silivri","sultanbeyli","sultangazi","şile","şişli","tuzla","ümraniye","üsküdar","zeytinburnu"]
  // const townsMugla = ["muğla","bodrum","dalaman","datça","fethiye","kavaklıdere","kötekli","köyceğiz","marmaris","menteşe","milas","ortaca","seydikemer","ula","yatağan"]
  // const towns = townsAnkara.concat(townsIstanbul, townsMugla)
  // const townsEnglish = []
  // for(var i = 0; i < towns.length; i++) {
  //   townsEnglish[i] = towns[i].split("ş").join("s").split("ç").join("c").split("ö").join("o").split("ğ").join("g").split("ü").join("u").split("ı").join("i")
  // }
  // console.log(townsEnglish);
    
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/hakkimizda" element={<Hakkimizda />} />
          <Route path="/blog" element={<BlogMainpage />} />
          <Route path="/en-iyi-web-tasarim-siteleri" element={<Eniyiwebtasarimsiteleri imgName="technology-advancement-in-web-design.jpg" title="En iyi web tasarım siteleri" />} />
          <Route path="/web-tasarim-nedir" element={<WebTasarimNedir imgName="website-tasarim.png" title="Web tasarım nedir?" />} />
          <Route path="/web-sitesi-fiyatlari" element={<WebsitesiFiyatlari imgName="paralar.jpg" title="Web sitesi fiyatları 2023 yılında ortalama ne kadardır?" />} />
          <Route path="/seo-nedir" element={<SeoNedir imgName="seo.png" title="SEO nedir?" />} />
          <Route path="/yazilim-hizmeti-nedir" element={<Eniyiwebtasarimsiteleri imgName="technology-advancement-in-web-design.jpg" title="Yazılım hizmeti nedir?" />} />
          {towns.map((town, i)=>{
            // console.log(town, townsEnglish[i])
            
            return <Route key={i} exact path={`/${townsEnglish[i]}-web-tasarim`} element={<WebTasarim place={town.charAt(0).toUpperCase() + town.slice(1)} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} />} />
})}
        </Routes>
        
        <Footer />
      </BrowserRouter>
    
    </>
  );
}

export default App;
