import React, { useEffect } from "react"
import styles from "./Eniyiwebtasarimsiteleri.module.scss"
import { Link } from "react-router-dom"
import { Seo } from "../../../components/seo/Seo"
import Footer from "../../../components/footer/Footer"
import { cardsManual } from "../../../assets/data/TownsData"
import { Heading, TableOfContent } from "../../../components/customhooks/useHeadings"

const Eniyiwebtasarimsiteleri = ({title, imgName}) => {
  const allCards = cardsManual
  // const allCards = useSelector(selectCardsAll)
    useEffect(() => {
        window.scrollTo({top:0, behavior:"smooth"})
    }, [])
    
  return (
    <div className={styles.container}>
      <Seo
        title={`${title} | Web Tasarım | Bubi Yazılım `}
        description="Siz hayal edin, biz yapalım.."
        keywords="En iyi web tasarım siteleri, en iyi web site firması, "
      />
      <h1>{title}</h1>
      <section className={styles.splitSection}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img
              src={`/images/${imgName}`}
              alt={imgName}
            />
          </div>
          <p>
            En iyi web tasarım siteleri, fonksiyonel ve estetik ögelerinin
            birleşmesi sonucunda oluşmuştur.{" "}
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>
            , profesyonel ekibiyle kurumsal web tasarım sitelerine imza atıyor.
            <br />
            <br />
            En iyi web tasarım siteleri, kalitesiyle dikkat çekiyor. En iyi web
            tasarım siteleri diğer web sitelerine kıyasla hem ulaşılabilirlik
            hem de görünürlük açısından çok farklıdır. En iyi web tasarım
            siteleri profesyoneldir. İşletmeler ve şahıslar isimlerini
            duyurabilmek için web tasarıma ihtiyaç duymaktadır.
            <br />
            <br />
            Web site büyüyerek daha çok kişiye ulaştıkça sahiplerine daha çok
            kazanç sağlar. En iyi web tasarım siteleri, görünümleriyle
            dikkatleri üzerine çekiyor. Web sitenin kullanıcı deneyimi ve
            yapısına bakılarak en iyisi olup olmadığı net şekilde anlaşılabilir.
          </p>
          
          <TableOfContent />

          <Heading as="h2">En İyi Web Tasarım Sitelerinin Olmazsa Olmazları</Heading>
          <p>
            En iyi web tasarım siteleri için önemli olan bazı kriterler vardır.
            Bu kriterler şu şekilde ele alınabilir:
          </p>
          <Heading as="h3">Hosting</Heading>
          <p>
            Barındırma hizmeti, hosting olarak nitelendirilir. Ankara web
            tasarım çalışmalarında web site kurulurken hostinge özellikle
            ihtiyaç vardır.
            <br />
            <br />
            Hosting sayesinde bir web sitesinde bulundurulması gereken tüm
            unsurlar yer almaktadır. Bunlar; doküman, içerik, resim vb. şeklinde
            örneklendirilebilir. Web sitelerinin arka yüzü olarak
            nitelendirilir. Bir web sitesinin hostingi hizmet verilen ülkede yer
            almaktadır.
          </p>
          <Heading as="h3">Mobil Uyumlu Web Tasarım</Heading>
          <p>
            En iyi web tasarım siteleri olmazsa olmazları arasında mobil
            uyumlulukta yer alıyor. Mobil uyumlu web tasarım sayesinde siteye
            akıllı cep telefonları, tablet ve laptoptan rahat giriş
            yapılabiliyor. Bu sayede insanlar diledikleri yerden istedikleri
            şekilde internet sitelerine girebiliyorlar. Özellikle günümüzde
            akıllı cep telefonu kullanımının artmasıyla birlikte web sitelerinin
            mobil uyumlu olarak tasarlanması bir ihtiyaç haline dönüşmüştür.
            Mobil uyumlu web tasarım sayesinde bilgisayar ve cep telefonuna ayrı
            ayrı tasarım yapma ihtiyacı ortadan kalkar. Mobil uyumlu web tasarım
            hizmeti daha ekonomiktir.
          </p>
          <Heading as="h3">Özgün İçerik</Heading>
          <p>
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>
            , web tasarım çalışmalarında kurulan siteyi arama motorlarında üst
            sıralara çıkarmak için özgün içeriklere de yer veriyor. Uzman SEO
            içerik yazarları tarafından web sitelerine orijinal içerikler
            üretiliyor. Özgün içerikler, web sitesinin hem aktifliğini hem de
            arama motorlarındaki aramalarda üst sıralarda yer almasında çok
            belirleyicidir. Düzenli şekilde üretilen özgün içerikler web
            sitelerine yüklendikçe kurumsallık ön plana çıkar.
          </p>
          <Heading as="h3">Kullanışlı ve Modern Arayüz Tasarımı</Heading>
          <p>
            Ankara web tasarım çalışmalarında sitenin kullanışlı ve modern
            arayüze sahip olması büyük avantajdır. Bir web sitesinde yer alan
            görselin ziyaretçilerinin dikkatini çekecek şekilde tasarlanması çok
            önemlidir. İnternet kullanıcıları bir genel olarak web sitelerinde
            aradıklarına hemen ulaşmayı hedeflerler. Web sitesinde aranılan
            bilgiye hızlıca ulaşılması arayüz tasarımı noktasında çok önemlidir.
            Ayrıca arayüz tasarımında web sitesinde yer alan renklerin doğru
            kullanımı da hayati önem taşıyor. Göz alıcı ve canlı renkler web
            sitesine ziyaretçi çekmez. Ancak hem sade hem de açık renkler göz
            yormadığından dolayı daha çok web sitesine ziyaretçi çekiyor.
          </p>
          <Heading as="h3">Web Sitesinin Kullanılabilirliği</Heading>
          <p>
            Web siteleri, ziyaretçi beklentilerini mutlaka karşılamalıdır. Sade
            tasarımlardan oluşması web sitesinin trafiğinin artırılması
            açısından çok önemlidir.
          </p>
          <Heading as="h3">Alan Adı (Domain)</Heading>
          <p>
            Web sitelerinde olması gereken faktörlerden biri de alan adıdır.
            Domain olarak nitelendirilen alan adı, web sitesinin internetteki
            fiziksel adıdır. Domain olmadan bir web tasarım çalışmasının
            yapılması imkansızdır. Alan adı, belirli sürelerde kiralanmaktadır.
            En iyi web tasarım siteleri için gerekli olan domainin süresiz satın
            alınması mümkün değildir. Belirli aralıklarla yenileme yapılarak
            kiralama süresinde uzatmaya gidilir. Domain’ler sonlarında yer alan
            uzantılara göre sınıflanıyor. Uzantılar; “.net”, “.com”, “.org”
            şeklinde ifade edilebilir.
            <br />
            <br />
            “.net”; Network yani bilgisayar ağından kısaltılmıştır. İnternetle
            ilgili ticari amaçlı web sitelerinde daha çok kullanılmaktadır. Aynı
            zamanda Bilgilendirme amaçlı kurulan web sitelerinde de
            kullanılıyor.
            <br />
            “.com”; ticari amaçlı olarak kurulan web sitelerinde
            kullanılmaktadır. En çok tercih edilen domain uzantısıdır.
            <br />
            “.org”; ticari amaç gütmeyen web sitelerinde kullanılan bir
            domaindir.
          </p>
          <Heading as="h3">Teknik Destek</Heading>
          <p>
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>
            , web tasarım hizmetleri kapsamında müşterilerine teknik destekte
            sunuyor. Siteyle ilgili ortaya çıkan herhangi bir problemin çözümü
            için teknik destek almak şarttır. Web sitenin başarısının
            devamlılığı açısından teknik destek mutlaka sağlanmalıdır.
          </p>
          <Heading as="h3">Sosyal Medya Sayfalarına Entegrasyon</Heading>
          <p>
            En iyi web tasarım siteleri sosyal medya sayfalarına entegre
            edildiğinde daha hızlı hedefledikleri kitlelere ulaşırlar. Web
            sitesinde yer alan bazı bilgilendirici içerikler sosyal medya
            platformlarına entegre edildiğinde trafiği daha çok artırır. Aynı
            şekilde web sitesindeki kampanyalarda sosyal medya platformlarına
            entegre edilerek trafiği artırmaktadır. Bundan dolayı web
            sitelerinde sosyal medya linklerinin olması çok önemlidir. Web
            sitelerinde sosyal medya paylaşım butonları mutlaka olmalıdır.
          </p>
          <Heading as="h3">SEO (Arama Motoru Optimizasyonu) Ayarları</Heading>
          <p>
            Web sitelerinin Yandex, Yaani, Google, Yahoo Search ve Bing gibi
            arama motorlarının üst sıralarında yer alması çok önemlidir. Arama
            motorlarında görünür olmak web sitesinin internette kolay
            bulunmasını sağlıyor. Teknik SEO çalışmaları sayesinde web site
            trafiğini artırarak işletme ve markalara daha çok müşteri çeker.
            Arama motorları tarafından web sitesinin kalitesi devamlı olarak
            test edilmektedir. SEO çalışmaları düzenli yapıldığı takdirde web
            site daima üst sıralarda yer alır. En iyi web tasarım siteleri
            açısından SEO hizmeti mutlaka alınmalıdır.
          </p>
          <Heading as="h3">Google Map ile İletişim Formu</Heading>
          <p>
            Web sitesinin gerçek olup olmadığı; Google Map ve iletişim formundan
            net şekilde belli olur. Bir web sitesinin kaliteli ve profesyonel
            olması dikkatli hazırlanan iletişim formuyla belli olur. Ayrıca
            işletmenin konumunun belirtildiği Google Map’te ziyaretçilere güven
            verme açısından çok önemlidir.
          </p>
          <Heading as="h3">Hızlı Sunucu</Heading>
          <p>
            Web sitelerinin açılma hızı da kalitesini gösterir. Çok geç açılan
            web siteleri, internet kullanıcılarının sıkılmasına sebep olur. Bu
            sebeple web sitelerinde hızlı sunucu olması önemlidir. SEO açısından
            özellikle hızlı sunucu çok gereklidir.
          </p>
          <Heading as="h3">Hakkımızda Yazısı</Heading>
          <p>
            Ankara web tasarım çalışmaları doğrultusunda internet sitelerinde
            hakkımızda yazısı da mutlaka olmalıdır. Kaliteli bir şekilde
            oluşturulan hakkımızda yazısı işletmelerin güvenli olduğunun en
            büyük kanıtıdır. Hakkımızda yazısının yanı sıra en iyi web tasarım
            siteleri;
            <br />
            <br />
            Ekibimiz,
            <br />
            Vizyon,
            <br />
            Misyon gibi yazılardan oluşuyor.
            <br />
            <br />
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>{" "}
            ile iletişime geçerek kaliteli bir web siteye sizde sahip
            olabilirsiniz.
          </p>
          <img
            // src="/images/technology-advancement-in-web-design.webp"
            src={"/images/website-tasarim.png"}
            alt="technology advancement in web design"
            style={{ border: "none", borderRadius: "0" }}
          />
          <Heading as="h2">En İyi Web Tasarım Siteleri</Heading>
          <p>
            En iyi web tasarım siteleri, fonksiyonel ve estetik ögelerinin
            birleşmesi sonucunda oluşmuştur.{" "}
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>
            , profesyonel ekibiyle kurumsal web tasarım sitelerine imza atıyor.{" "}
            <br /> <br />
            En iyi web tasarım siteleri, kalitesiyle dikkat çekiyor. En iyi web
            tasarım siteleri diğer web sitelerine kıyasla hem ulaşılabilirlik
            hem de görünürlük açısından çok farklıdır. En iyi web tasarım
            siteleri profesyoneldir. İşletmeler ve şahıslar isimlerini
            duyurabilmek için web tasarıma ihtiyaç duymaktadır.
            <br /> <br />
            Web sitesi büyüyerek daha çok kişiye ulaştıkça sahiplerine daha çok
            kazanç sağlar. En iyi web tasarım siteleri, görünümleriyle
            dikkatleri üzerine çekiyor. Web sitenin kullanıcı deneyimi ve
            yapısına bakılarak en iyisi olup olmadığı net şekilde anlaşılabilir.
          </p>
          <Heading as="h2">Sonuç</Heading>
          <p>
            Siz de işinizi hayal bile edemeyeceğiniz kadar büyütmek, çok daha
            fazla müşteriye ulaşabilmek ve profesyonel bir dijital kimlik
            kazanmak için{" "}
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>{" "}
            ile iletişime geçebilirsiniz.
          </p>
        </div>

        <div className={styles.right}>
          <h3>Son Paylaşımlar</h3>

          <Link to={allCards[0].link} target="_blank">
            <strong>{allCards[0].title}</strong>
          </Link>
          <Link to={allCards[1].link} target="_blank">
            <strong>{allCards[1].title}</strong>
          </Link>
          <Link to={allCards[2].link} target="_blank">
            <strong>{allCards[2].title}</strong>
          </Link>
          <Link to={allCards[3].link} target="_blank">
            <strong>{allCards[3].title}</strong>
          </Link>
          <Link to={allCards[4].link} target="_blank">
            <strong>{allCards[4].title}</strong>
          </Link>
        </div>
        
      </section>

      <section className={styles.bottomTags}>
        <Link to="/">web tasarım</Link>
        <Link to="/">web sitesi</Link>
        <Link to="/">web sitesi tasarımı</Link>
        <Link to="/">web sitesi fiyatları</Link>
        <Link to="/">site tasarımı</Link>
        <Link to="/">web yazılım</Link>
        <Link to="/">web tasarım fiyatları</Link>
        <Link to="/">website</Link>
      </section>
      
      {/* <Footer /> */}
    </div>
  )
}

export default Eniyiwebtasarimsiteleri
