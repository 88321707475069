import { combineReducers, configureStore } from "@reduxjs/toolkit";
import navReducer from "./slice/navSlice";

const rootReducer = combineReducers({    
    nav: navReducer,
})

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export default store