import React, { useEffect } from "react"
import styles from "./WebTasarim.module.scss"
import { Link } from "react-router-dom"
import webdesignFoto from "../../assets/images/mobil-uyumlu-web-tasarim.png"
import haritaTurkey from "../../assets/images/haritaTurkey.png"
import {
  townsEnglish,
  townsAnkara,
  townsIstanbul,
  townsMugla,
  townsAydin,
  townsIzmir,
  townsAntalya,
  townsBursa,
  cardsManual,
} from "../../assets/data/TownsData"
import { Seo } from "../../components/seo/Seo"
import Footer from "../../components/footer/Footer"
import { Heading, TableOfContent } from "../../components/customhooks/useHeadings"
import ScrollTopButton from "../../components/scrolltopbutton/ScrollTopButton"

const WebTasarim = ({ place, title }) => {
  const allCards = cardsManual

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  // const townsAnkara = ["ankara","akyurt","ayaş","altındağ","balâ","batıkent","çamlıdere","beypazarı","çankaya","elmadağ","çubuk","etimesgut","gölbaşı","evren","haymana","güdül","kahramankazan","keçiören","kızılcahamam","kalecik","nallıhan","mamak","polatlı","sincan","pursaklar","şereflikoçhisar","yenimahalle"]
  // const townsMugla = ["muğla","bodrum","dalaman","datça","fethiye","kavaklıdere","kötekli","köyceğiz","marmaris","menteşe","milas","ortaca","seydikemer","ula","yatağan"]
  // const townsIstanbul = ["istanbul", "adalar","arnavutköy","ataşehir","avcılar","bağcılar","bahçelievler","bakırköy","başakşehir","bayrampaşa","beşiktaş","beykoz","beylikdüzü","beyoğlu","büyükçekmece","çatalca","çekmeköy","esenler","esenyurt","eyüpsultan","fatih","gaziosmanpaşa","güngören","kadıköy","kağıthane","kartal","küçükçekmece","maltepe","pendik","sancaktepe","sarıyer","silivri","sultanbeyli","sultangazi","şile","şişli","tuzla","ümraniye","üsküdar","zeytinburnu"]
  // const towns = townsAnkara.concat(townsIstanbul, townsMugla)
  const townsOfInterest = townsAnkara.includes(place.toLowerCase())
    ? townsAnkara
    : townsIstanbul.includes(place.toLowerCase())
    ? townsIstanbul
    : townsMugla.includes(place.toLowerCase())
    ? townsMugla
    : townsAydin.includes(place.toLowerCase())
    ? townsAydin
    : townsIzmir.includes(place.toLowerCase())
    ? townsIzmir
    : townsAntalya.includes(place.toLowerCase())
    ? townsAntalya
    : townsBursa.includes(place.toLowerCase())
    ? townsBursa
    : []

  const imageUrl = `/images/${townsOfInterest[0]
    ?.split("ş")
    .join("s")
    .split("ç")
    .join("c")
    .split("ö")
    .join("o")
    .split("ğ")
    .join("g")
    .split("ü")
    .join("u")
    .split("ı")
    .join("i")}-web-tasarim.jpg`

    const strToEnglish = (string) => string.split("ş").join("s").split("ç").join("c").split("ö").join("o").split("ğ").join("g").split("ü").join("u").split("ı").join("i")


  // const townsEnglish = []
  // for(var i = 0; i < towns.length; i++) {
  //   townsEnglish[i] = towns[i].split("ş").join("s").split("ç").join("c").split("ö").join("o").split("ğ").join("g").split("ü").join("u").split("ı").join("i")
  // }
  // console.log(townsEnglish);
  // console.log(imageUrl);

  // const paraxImgRef = useRef(null)

  // useEffect(()=> {
  //     window.addEventListener('scroll', handleScroll)
  //         return () => {
  //             window.removeEventListener('scroll', handleScroll)
  //         }
  // }, [])

  // const handleScroll = () => {
  //   console.log(window.scrollY);
  //   paraxImgRef.current.style.backgroundPositionY = window.scrollY + 'px'
  // }

  const [hasMounted, setHasMounted] = React.useState(false);
  React.useEffect(() => {
    setHasMounted(true);
  }, []);
  if (!hasMounted) {
    return null;
  }

  return ( hasMounted &&
    <>
      <Seo
        title={`${place} Web Tasarım | Bubi Yazılım | Dijital Kimliğiniz`}
        description={`${place} bölgesinde Profesyonel Web Sitesi, Mobil Uygulama, SEO Hizmetleri, Logo Tasarımı vb. şirketinizin ihtiyacına yönelik teknoloji çözümleri, Web &amp; Mobil Tabanlı Uygulamalar, SEO geliştirmeleri, logo tasarımları vb her konuda uzman benzersiz bir şirket olarak hizmet vermekteyiz.`}
        keywords={`${place} web Tasarım, ${place} web site,${place} website,${place} web sitesi fiyatları, ${place} mobil uygulama, ${place} cep telefonu programı, ${place} SEO hizmeti`}
      />
      
    <div className={styles.containerWebTasarim}>
      <h1>{place} Web Tasarım</h1>
      <section className={styles.splitSection}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={imageUrl} alt={title} loading="lazy"/>
          </div>
          <p>
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>
            , özellikle {place} bölgesindeki şirketlerin çevrimiçi varlıklarını
            güçlendirmek ve dijital dünyada öne çıkarmak için önemli bir role
            sahiptir. Profesyonel ve yaratıcı web tasarım çözümleri, markanızı
            etkileyici bir şekilde temsil ederken kullanıcı deneyimini ve
            müşteri kitlesini önemli seviyelere artırabilir. {place}{" "}
            içerisinde kaliteli web tasarım hizmeti almanın neden bu kadar
            önemli olduğuna dair bazı nedenler altta sıralanmıştır.
          </p>

          <TableOfContent />
          
          <Heading as="h2">İyi bir web sitesinde olması gerekenler nelerdir?</Heading>

          <Heading as="h3">Görsel Estetik Dizayn ve Kurumsal Marka Kimliği</Heading>
          <p>
            {place} içerisindeki önde gelen yazılım ve web tasarım şirketleri,
            görsel estetik ve marka kimliği konularında uzmanlaşmış ekipler
            sunar. Web siteniz, markanızın değerlerini yansıtan profesyonel ve
            çarpıcı bir tasarıma sahip olmalıdır.
          </p>
          <Heading as="h3">Mobil Uyumlu Tasarım</Heading>
          <p>
            {place} web tasarım uzmanları, mobil cihazlarda mükemmel görünen ve
            kullanılabilirlik sağlayan tasarımlar oluşturabilir. Mobil uyumlu
            bir web sitesi, kullanıcıların her türlü cihazda sorunsuz bir
            deneyim yaşamasını sağlar.
          </p>
          <Heading as="h3">SEO Uyumlu Yapı</Heading>
          <p>
            Kaliteli {place} web tasarım hizmetleri, arama motorlarına dost bir
            yapıya sahip siteler oluşturabilir. SEO uyumlu bir tasarım, web
            sitenizin arama sonuçlarında daha üst sıralarda yer almasına
            yardımcı olur.
          </p>
          <Heading as="h3">Hız ve Performans</Heading>
          <p>
            {place} bölgesindeki web tasarım profesyonelleri, hızlı yükleme
            süreleri ve mükemmel performans sağlamak için optimizasyon
            tekniklerini kullanabilir. Kullanıcılar hızlı açılan ve sorunsuz
            çalışan bir web sitesi bekler.
          </p>
          <Heading as="h3">İçerik Yönetim Sistemi (CMS)</Heading>
          <p>
            {place} bölgesinde web tasarım hizmeti alırken, kullanımı kolay ve
            güncellemelere açık bir içerik yönetim sistemi (CMS) ile sitenizi
            yönetebilirsiniz. Bu da içerik güncellemelerini kolaylaştırır.
          </p>
          <Heading as="h3">Etkili İletişim Araçları</Heading>
          <p>
            Web sitenizin iletişim araçları, ziyaretçilerin sizinle kolayca
            iletişime geçmelerini sağlar. Ankara web tasarım uzmanları, etkili
            iletişim araçlarına vurgu yaparak müşteri etkileşimini artırır.
          </p>
          <h3>Referanslar ve Portföy</h3>
          <p>
            {place} içerisinde web tasarım hizmeti sunan yazılım şirketlerinin
            referansları ve portföyü, geçmiş projelerini ve müşteri
            memnuniyetini gösterir. Bu da güvenilir bir seçim yapmanıza yardımcı
            olur.
          </p>
          <br />
          <p>
            Bir <strong>{place} web tasarım</strong> firması olarak, bizden
            hizmet aldığınız takdirde çevrimiçi varlığınızı en iyi şekilde
            yönetebilir ve dijital dünyada rekabet avantajı elde edebilirsiniz.
          </p>

          <Heading as="h2">Hangi bölgelerde hizmet vermekteyiz?</Heading>
          <p>
            Başta {place} içerisinde olmak üzere, Türkiye'nin her yerine hizmet
            vermekteyiz. {place} içinde biraz araştırma yaptığınız takdirde,
            hizmet kalitemizden müşterilerimizin ne ölçüde memnuniyet duyduğunu
            fark edeceksiniz. Çok hızlı şekilde gelişen {place} bölgemiz, üst
            üste yeni farklı sektörlerde şirketler açılması sonucunda, bu
            şirketlerin dijital kimlik kazanmak amacıyla web tasarımı, logo
            tasarımı, mobil uygulama ihtiyaçları için bizlere yoğun şekilde
            başvuru gelmektedir. Siz de alttaki iletişim bilgileri üzerinden
            güvenle irtibata geçebilirsiniz.
          </p>
          <img
            src={haritaTurkey}
            alt="Türk Bayraklı Türkiye Haritası"
            style={{ border: "none" }}
            loading="lazy"
          />
          <Heading as="h2">{`${place} içinde web sitesi fiyatları ortalama ne kadardır?`}</Heading>
          <p>
            {place} bölgesi için web sitesi fiyatları en düşük 5,000 TL olmak
            üzere, son fiyatın belirlenmesinde yapılan web sitesinin içeriği,
            bulut tabanlı olup olmaması, SEO uyumlu olması, özel tasarım olup
            olmaması, dinamik altyapıya sahip olması vb birçok etken rol
            oynamaktadır. {place} bölgesinde, daha öncesinde düşük kalite ve
            işlevleri bozuk yapıda web siteleri için fahiş ücretler ödeyip
            memnun kalmayan müşterilerle sık sık karşılaşmaktayız. Bu sebeple
            web sitesi yaptırmak için doğru yer seçimi, hem vakit hem de para
            kaybı yaşamamanız için büyük önem arz etmektedir.
          </p>
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(${imageUrl}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <Heading as="h2">Web tasarımı nedir?</Heading>
          <p>
            Web tasarımı, bir web sitesindeki içeriği tüm dünyayla çevrimiçi
            olarak paylaşılabilecek ve erişilebilecek şekilde planlama,
            düzenleme ve tasarım sanatıdır. Estetik ve işlevsel unsurların
            birleşimi olan web tasarımı, bir web sitesinin görünümünü (renkleri,
            yazı tipleri, grafikleri ve kullanıcı arayüzü gibi) belirleyen bir
            tür dijital tasarımdır. Bugün, bir web sitesi oluşturmak, çevrimiçi
            varlığa sahip olmanın temel direklerinden biridir. Bu nedenle, web
            tasarım dünyası her zamankinden daha fazla dinamiktir. Web sitesi
            sahiplerinin ve benzer şekilde ziyaretçilerin artan ihtiyaçlarını
            karşılamak için mobil uygulamalar ve kullanıcı arayüzü tasarımı da
            dahil olmak üzere sürekli olarak gelişmektedir. Web tasarımı
            genellikle, grafik tasarımdan SEO optimizasyonuna ve UX'e kadar
            ilgili sektörlerden bilgi ve araçları birleştiren ortak bir
            süreçtir. Web tasarımcıları genellikle bu alanlardan, performansı
            optimize edebilen ve daha büyük süreç ve sonuca odaklanabilen
            profesyonelleri bir araya getirir. Siz {place} sakinlerine
            memnuniyetle hizmet vermekteyiz.
          </p>
          <div
            className={styles.paraxImg}
            style={{
              background: `url(${webdesignFoto}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <Heading as="h2">Web Tasarımcıları Ne Yapar?</Heading>
          <p>
            Web tasarımcıları, müşterinin gereksinimlerini göz önünde
            bulundurarak teknik uzmanlıkları ve bilgileriyle bir web sitesinin
            tasarımını ve düzenini oluşturur. UI (kullanıcı arayüzü) ve UX
            (kullanıcı deneyimi) hakkında derin bir anlayışa sahiptirler ve
            görsel öğeler oluşturmak için tasarım programlarını kullanma
            konusunda ustadırlar. Web tasarımcıları, becerileri ve uzmanlıkları
            ile markanın kimliğini web sitesinin tasarım öğelerinde yakalar.
            Ziyaretçiler, estetiğine ve gezinme açısından ne kadar sezgisel
            olduğuna bağlı olarak belirli bir web sitesiyle ilgili deneyimlerini
            derecelendirir. Bu nedenle, web tasarımcıları bir web sitesini
            olabildiğince etkileşimli hale getirmek için animasyonlar,
            grafikler, simgeler, logolar ve diğer öğeleri oluşturmak için Adobe,
            CorelDraw, Inkscape ve diğer tasarım programlarını kullanır. Web
            tasarımcıları ayrıca web sitesinin yaratıcı ve görsel yönüne
            odaklanır ve ziyaretçilerin ilgisini çekmek için simetri, renk
            sistemleri, orantılar vb. gibi çeşitli tasarım uygulamalarını takip
            eder. Ek olarak, kodlama dillerini kullanarak yazı tipleri,
            görüntülerin ve videoların yerleştirilmesi ve düzenlerin
            oluşturulması üzerinde çalışırlar.
          </p>
          <Heading as="h2">İyi bir web sitesi nasıl olmalıdır?</Heading>
          <p>
            İyi bir web tasarımı, markanızın hedef kitlenizle konuşma şeklini
            değiştirebilir. Şirketler, web tasarımlarına her zamankinden daha
            fazla odaklanıyor ve sunabileceği görünür faydalar için profesyonel
            web tasarımcıları arıyor. Bu avantajlardan bazıları şunlardır:
          </p>
          <br />
          <Heading as="h3">Etkili Bir İlk İzlenim</Heading>
          <p>
            İyi tasarlanmış bir web sitesiyle, harika bir ilk izlenim bırakmak
            daha kolay hale gelir. Markalar için her şey, potansiyel
            müşterilerin veya müşterilerin işlerini nasıl gördükleriyle
            ilgilidir ve bu, web sitelerini nasıl gördüklerinden büyük ölçüde
            etkilenir. Web siteniz markanızı temsil eder ve web tasarımına
            odaklandığınızda, çekici ve güçlü bir ilk izlenim yaratmanıza
            yardımcı olabilir.
          </p>
          <Heading as="h3">Google vb Arama Motorlarında Üst Sıralarda Yer Alma</Heading>
          <p>
            Web tasarımı, web sitenize daha iyi Google sıralamaları
            kazandırabilecek çeşitli öğelere sahip olabilmektedir. Bu
            unsurlardan bazıları okunabilirlik, mobil uyumluluk, web sitesi
            hızı, site haritaları, URL yapısı ve web sitesinde gezinmeyi içerir.
            İyi bir web tasarımı, hem hedef kitleniz hem de arama motoru için
            size daha fazla görünürlük sağlayan bu unsurları web sitesine dahil
            eder.
          </p>
          <Heading as="h3">Marka Tutarlılığı</Heading>
          <p>
            Logo, renk şeması ve yazı tipleri gibi markanızın birkaç temel
            varlığı, hedef kitlenizin markanızı hatırlamasını ve onu binlerce
            diğerlerinden kolayca ayırt etmesini sağlayabilir. Profesyonel bir
            web tasarımcısı, işinizin tüm benzersiz yönlerini dikkate alır ve
            markanızın güvenilirliğini artırmak ve tutarlılığı tasvir etmek için
            bunları web sitenize harmanlar.
          </p>
          <Heading as="h3">Ziyaretçilerin Web Sitenizde Gezinme Süresi</Heading>
          <p>
            Web siteniz sürükleyici ve ilginç olduğunda, ziyaretçileri daha
            derine inmeye ve web sitesinin farklı bölümlerini keşfetmeye teşvik
            eder. Aynı şekilde, karmaşık ve gezinmesi zor görünen bir web
            sitesi, ziyaretçileri saniyeler içinde siteden çıkmaya sevk
            edebilir. Hemen çıkma oranları, web sitenizin güvenilirliğini ve
            sıralamalarını da etkiler. Web siteniz ziyaretçileri daha uzun süre
            tutacak şekilde tasarlandığında erken çıkışlardan kaçınabilir ve
            ziyaretçilerin kalma sürelerini uzatabilirsiniz.
          </p>
          <Heading as="h2">SEO nedir?</Heading>
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/question.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            SEO, yani arama motoru optimizasyonu, bir web sitesini arama
            motorlarında iyi sıralanacak şekilde optimize etme sürecidir. Web
            sitenizin başarısında büyük paya sahip olduğu için fonksiyonel
            unsurlarla birlikte burada yer almayı hak ettiğini düşünüyoruz. Sizi
            Google'da bulabilen daha fazla kişi, web sitenizi kullanan daha
            fazla ziyaretçiniz olacağı anlamına gelir. SEO sonradan kısmen
            eklenebilse de, web sitenizin en başından SEO ile uygun
            çalışabilmesi için ilk kuruluş aşamasında yapılması gerekenler
            vardır. Yani ilk web siteniz yayınlamadan önce uygulayabileceğiniz
            bir dizi yaygın SEO uygulaması da vardır: içeriğinize başlıklar
            ekleyin, resimlere alternatif metin ekleyin, ilgili sayfalarda meta
            açıklamaları kullanın ve markanızı veya işletmenizi temsil eden bir
            alan adı seçin. Bu işi tam anlamıyla yapabilmek için yıllar alan bir
            tecrübe birikimi gerekmektedir. Sonuçta elde edilecek kazanım için
            örnek vermek gerekirse, "{place} kebapçı" şeklinde yapılan bir
            aramada, rahatlıkla en üstlerde çıkabilmenizi ve bu şekilde
            rakiplerinize fark atmanızı sağlıyoruz.
          </p>

          <Heading as="h2">Alan Adı (Domain) Nedir?</Heading>
          <p>
            Web sitelerinde olması gereken faktörlerden biri de alan adıdır.
            Domain olarak nitelendirilen alan adı, web sitesinin internetteki
            fiziksel adıdır. Domain olmadan bir web tasarım çalışmasının
            yapılması imkansızdır. Alan adı, belirli sürelerde kiralanmaktadır.
            En iyi web tasarım siteleri için gerekli olan domainin süresiz satın
            alınması mümkün değildir. Belirli aralıklarla yenileme yapılarak
            kiralama süresinde uzatmaya gidilir. Domain’ler sonlarında yer alan
            uzantılara göre sınıflanıyor. Uzantılar; “.net”, “.com”, “.org”
            şeklinde ifade edilebilir.
            <br />
            “.net”; Network yani bilgisayar ağından kısaltılmıştır. İnternetle
            ilgili ticari amaçlı web sitelerinde daha çok kullanılmaktadır. Aynı
            zamanda Bilgilendirme amaçlı kurulan web sitelerinde de
            kullanılıyor.
            <br />
            “.com”; ticari amaçlı olarak kurulan web sitelerinde
            kullanılmaktadır. En çok tercih edilen domain uzantısıdır.
            <br />
            “.org”; ticari amaç gütmeyen web sitelerinde kullanılan bir
            domaindir.
          </p>

          <Heading as="h2">SEO (Arama Motoru Optimizasyonu) Ayarları Nasıl Yapılır?</Heading>
          
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/question2.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            Web sitelerinin Yandex, Yaani, Google, Yahoo Search ve Bing gibi
            arama motorlarının üst sıralarında yer alması çok önemlidir. Arama
            motorlarında görünür olmak web sitesinin internette kolay
            bulunmasını sağlıyor. Teknik SEO çalışmaları sayesinde web site
            trafiğini artırarak işletme ve markalara daha çok müşteri çeker.
            Arama motorları tarafından web sitesinin kalitesi devamlı olarak
            test edilmektedir. SEO çalışmaları düzenli yapıldığı takdirde web
            site daima üst sıralarda yer alır. En iyi web tasarım siteleri
            açısından SEO hizmeti mutlaka alınmalıdır.
          </p>
        </div>

        <div className={styles.right}>
          <h3>Son Paylaşımlar</h3>

          <Link to={allCards[0].link} target="_blank">
            <strong>{allCards[0].title}</strong>
          </Link>
          <Link to={allCards[1].link} target="_blank">
            <strong>{allCards[1].title}</strong>
          </Link>
          <Link to={allCards[2].link} target="_blank">
            <strong>{allCards[2].title}</strong>
          </Link>
          <Link to={allCards[3].link} target="_blank">
            <strong>{allCards[3].title}</strong>
          </Link>
          <Link to={`/${townsOfInterest[0].split("ş").join("s").split("ç").join("c").split("ö").join("o").split("ğ").join("g").split("ü").join("u").split("ı").join("i")}-web-tasarim`} target="_blank">
            Profesyonel Web Tasarım{" "}
            {townsOfInterest[0].charAt(0).toUpperCase() +
              townsOfInterest[0].slice(1)}
          </Link>
        </div>

      </section>

      <section className={styles.hizmetBolgeleri}>
        <h3>{title} Hizmet Bölgeleri</h3>
        <div className={styles.linksContainer}>
          {townsEnglish &&
            townsOfInterest.map((town, i) => (
              <Link key={town} to={`/${strToEnglish(town)}-web-tasarim`} target="_blank">
                {town} web tasarım
              </Link>
            ))}
        </div>
      </section>

      <section className={styles.bottomTags}>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web sitesi</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web sitesi tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web sitesi fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web site</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} kurumsal web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} profesyonel web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web yazılım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} web tasarım fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım {place}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{place} website</strong>
        </Link>
      </section>

      <ScrollTopButton />

      {/* <Footer /> */}
      
      </div>
    </>
  )
}

export default WebTasarim
