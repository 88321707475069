import React, { useCallback, useEffect, useState } from "react"
import styles from "./Home.module.scss"
import { Link, useNavigate } from "react-router-dom"
import { HiOutlineLightBulb } from "react-icons/hi"
import { GiMoneyStack } from "react-icons/gi"
import { FaPeopleCarry } from "react-icons/fa"
import Card from "../../components/card/Card"
import perdeHakkimizda from "../../assets/images/perdehakkimizda.jpg"
import hizmet1 from "../../assets/images/hizmet1.jpg"
import hizmet2 from "../../assets/images/hizmet2.jpg"
import hizmet3 from "../../assets/images/hizmet3.jpg"
import hizmet4 from "../../assets/images/hizmet4.jpeg"
import hizmet5 from "../../assets/images/hizmet5.jpeg"
import hizmet6 from "../../assets/images/hizmet6.jpeg"
import hizmet7 from "../../assets/images/hizmet7.jpeg"
import hizmet8 from "../../assets/images/hizmet8.jpeg"
import hizmet9 from "../../assets/images/hizmet9.jpeg"
import hizmet10 from "../../assets/images/hizmet10.jpeg"
import hizmet11 from "../../assets/images/hizmet11.jpeg"
import hizmet12 from "../../assets/images/hizmet12.jpeg"
import tirnak from "../../assets/images/tirnak.svg"
import StarsRating from "react-star-rate"
import useEmblaCarousel from "embla-carousel-react"
import { DotButton } from "../../components/carousel/EmblaCarouselArrowsDotsButtons"
import Fade from "react-reveal/Fade"
import Bounce from "react-reveal/Bounce"
// import useIntersection from "../../components/customhooks/useIntersection"
// import { Fade, Slide, Zoom } from 'react-awesome-reveal';
import ScrollReveal from "scrollreveal"
import MainSlider from "../../components/mainslider/MainSlider"
import ParallaxSection from "../../components/parallaxsection/ParallaxSection"
import ScrollTopButton from "../../components/scrolltopbutton/ScrollTopButton"

const Home = () => {
  const navigate = useNavigate()

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [carouselReady, setCarouselReady] = useState(false)
  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    // setPrevBtnEnabled(emblaApi.canScrollPrev())
    // setNextBtnEnabled(emblaApi.canScrollNext())
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    // onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on("select", onSelect)
    emblaApi.on("reInit", onSelect)
  }, [emblaApi, setScrollSnaps, onSelect])

  useEffect(() => {
    emblaApi?.reInit()
  }, [emblaApi])

  const comments = ["", "", ""]

  var slideFromLeft = {
    distance: "150%",
    origin: "left",
    opacity: 0,
    duration: 500,
    reset: true,
    mobile: true,
    cleanup: true
  }

  var slideFromRight = {
    distance: "150%",
    origin: "right",
    opacity: 0,
    duration: 500,
    reset: true,
    mobile: true,
  }

  var slideFromBottom = {
    distance: "40%",
    origin: "bottom",
    opacity: 0,
    duration: 1000,
    easing: "ease-out",
    reset: true,
    mobile: true,
  }

  var scaleUp = {
    scale: 0.8,
    distance: "5%",
    duration: 1000,
    opacity: 0,
    cleanup: true,
    reset: true,
  }

  var scaleUpFull = {
    scale: 0.4,
    duration: 600,
    opacity: 0,
    cleanup: true,
    reset: true,
    mobile: true,
  }

  var slideFromLeftSmall = {
    distance: "5%",
    origin: "left",
    opacity: 0,
    duration: 1000,
    easing: "ease-out",
    reset: true,
    mobile: true,
  }

  var fadeUp = {
    distance: "15%",
    origin: "bottom",
    opacity: 0,
    duration: 1000,
    easing: "ease-out",
    reset: true,
    mobile: true,
  }

  var fadeLeft = {
    distance: "20%",
    origin: "left",
    opacity: 0,
    duration: 1000,
    easing: "ease-out",
    reset: true,
    mobile: true,
  }

  var fadeRight = {
    distance: "20%",
    origin: "right",
    opacity: 0,
    duration: 1000,
    easing: "ease-out",
    reset: true,
    mobile: true,
  }

  // const inViewport = useInViewport()
  // const modalRef = useRef();
  // const inViewport = useIntersection(modalRef, '0px');

  
  // return in useEffect acts like componentDidUnmount
  useEffect(()=>{
    setTimeout(() => ScrollReveal().delegate(), 250) // bunu yapmazsan imageler yüklenmedigi icin position kayıyo
    ScrollReveal().reveal(".xx", slideFromLeft)
    ScrollReveal().reveal(".modalContainer", slideFromBottom)
    ScrollReveal().reveal(".card", scaleUpFull)
    ScrollReveal().reveal(".image", slideFromBottom)
    ScrollReveal().reveal(".text", slideFromLeftSmall)
    ScrollReveal().reveal(".location", fadeUp)
    ScrollReveal().reveal(".firstSection", slideFromLeft)
    // ScrollReveal().reveal(".secondSection", slideFromRight)
    ScrollReveal().reveal(".incele", fadeLeft)
    ScrollReveal().reveal(".basvur", fadeRight)
    ScrollReveal().reveal(".hakkimizdatext", slideFromLeft)

    // console.log("entered");
    setCarouselReady(true)
    return ()=> {
      setCarouselReady(false)
      // console.log("leaved")
    }
 },[])

  return (
    <div>
      <div className={styles.home}>
        {/* <Fade  > */}
        <MainSlider carouselReady={carouselReady} />
        <section className="firstSection">
          <h1>
          Perde’de Mimari Tasarım ve Uygulama Bizim İşimiz. <br />
Siz Hayal Edin Biz Yapalım.
          </h1>
          <div className={styles.buttonContainer}>
            <a className="incele" href="/hakkimizda">
              Detaylar
            </a>
            <a
              className="basvur"
              href="https://wa.me/905052575886?text=Merhaba,%0aBilgi%20alabilir%20miyim%3F"
              target="_blank"
              rel="noopener noreferrer"
            >
              Bize ulaşın
            </a>
          </div>
        </section>
        {/* </Fade> */}

        <section>
          <div className={`${styles.modalContainer} modalContainer`}>
            {/* <ModalVideo classNames={styles.modal} channel="youtube" isOpen={true} videoId='DpMux1uZXTg' youtube={{autoplay:0, cc_load_policy:1}} /> */}
            {/* <ModalVideo classNames={styles.modal} channel='custom' url='https://www.youtube-nocookie.com/embed/DpMux1uZXTg' isOpen={true} videoId='DpMux1uZXTg' youtube={{autoplay:0, cc_load_policy:1}} /> */}
            <iframe
              title="Sıfır Faizli Kredi Destek Programı"
              id="ytplayer"
              type="text/html"
              allowFullScreen
              src="https://www.youtube-nocookie.com/embed/mRGKjneeCtQ?autoplay=0&origin=http://example.com"
            />
          </div>

          <div className={styles.cardContainer}>
            {/* <Bounce onExit > */}
            <Link to="/Arge">
              <Card cardClass={`${styles.card} card`}>
                {/* <img
                    src={require('./../../assets/images/feature-tile-icon-01.svg')}
                    alt="ARGE ve İnovasyon Desteği"
                    width={64}
                    height={64} /> */}

                <HiOutlineLightBulb className={styles.icon} size={60} />

                <h2>Vizyonumuz</h2>
                <h5>
                Hizmetlerimizde Sektörümüzün Lideri Olarak Yön Vermek.
                </h5>
              </Card>
            </Link>
            {/* </Bounce> */}

            <Link to="/Arge">
              <Card cardClass={`${styles.card} card`}>
                {/* <img
                    src={require('./../../assets/images/feature-tile-icon-01.svg')}
                    alt="ARGE ve İnovasyon Desteği"
                    width={64}
                    height={64} /> */}

                <FaPeopleCarry className={styles.icon} size={60} />

                <h2>Misyonumuz</h2>
                <h5>
                Yenilikçi Politikalarımızla Her Bütçeye Uygun Hizmetler.
                </h5>
              </Card>
            </Link>

            <Link to="/Kredi">
              <Card cardClass={`${styles.card} card`}>
                {/* <img
                    src={require('./../../assets/images/feature-tile-icon-01.svg')}
                    alt="ARGE ve İnovasyon Desteği"
                    width={64}
                    height={64} /> */}

                <GiMoneyStack className={styles.icon} size={60} />

                <h2>Değerlerimiz</h2>
                <h5>
                Müşteri Memnuniyeti Odaklı Hizmet Vermekteyiz
                </h5>
              </Card>
            </Link>
          </div>
        </section>

        

        <section className={`${styles.split} secondSection`}>
        <div className={styles.row}>
            <div className={`${styles.left} hakkimizdatext`} style={{alignItems:"center"}}>
              <h2>Hakkımızda</h2>
              <p>
              33 Yıllık Perdecilik Sektöründe Kumaşın Bizim Ustalığımızla Dans’ını İzleyin.

Uygun fiyatlı hizmetlerimizle tamamen müşteri memnuniyeti odaklı değerlerimiz ile sizlerin hizmetinizdeyiz. <br /> <br /> Belirttiğiniz tüm alanlarda Stor, Perde, Mefruşat ve Korniş gibi sektörümüze ait tüm ürünlerin en kalitelisi ile sizlerleyiz.
          
              </p>
            </div>

            <div className={`${styles.right} image`}>
              <img
                onClick={() => navigate("/kredi")}
                src={perdeHakkimizda}
                alt="Perde Montajı"
              />
            </div>
          </div>
        </section>

        <section className={styles.split}>
        <h2>Neler Yapıyoruz?</h2>
          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>En güzel tasarımlar</h6>
              <h3>Perde Çeşitleri</h3>
              <p>
              Tüm Alana ve Çevreye uygun Perde Çeşitleri İle Tamamen İhtiyacınıza Yönelik Hizmet Vermekteyiz.
              </p>
            </div>

            <div className={`${styles.right} image`}>
              <img
                onClick={() => navigate("/kredi")}
                src={hizmet1}
                alt="Sıfır Faizli Kredi Desteği"
              />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet2} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>Tüm stor modelleri</h6>
              <h3>Stor Çeşitleri</h3>
              <p>
              Tüm Alana ve Çevreye uygun Stor Çeşitleri İle Tamamen İhtiyacınıza Yönelik Hizmet Vermekteyiz.
              </p>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>Çeşitli aksesuarlar</h6>
              <h3>Mefruşat Korniş Çeşitleri</h3>
              <p>
              Tüm Alana ve Çevreye uygun Perde ve Stor Aksesuarları ve Çeşitleri İle Tamamen İhtiyacınıza Yönelik Hizmet Vermekteyiz.
              </p>
            </div>

            <div className={`${styles.right} image`}>
              <img src={hizmet3} alt="Girişimcilik Desteği" />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet4} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Plicell Perde</h3>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Ahşap jaluzi</h3>
            </div>

            <div className={`${styles.right} image`}>
              <img src={hizmet5} alt="Sıfır Faizli Kredi Desteği" />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet6} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Ahşap jaluzi ve farba</h3>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>İhtiyaca yönelik hizmetler</h6>
              <h3>Sun screen güneş kırıcı stor perde</h3>
              <p>
              İçeriden dışarısı gözükür dışarıdan içerisi görünmez ve aynı zamanda orijinal ürünler gönül rahatlığı ile temizlenip 20 yıl en az kullanilabilir
              </p>
            </div>

            <div className={`${styles.right} image`}>
              <img src={hizmet7} alt="Sıfır Faizli Kredi Desteği" />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet8} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Katlamalı tül perde ve fon kumaş çalışması</h3>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Tül, fon, ve farba çalışması</h3>
            </div>

            <div className={`${styles.right} image`}>
              <img src={hizmet9} alt="Sıfır Faizli Kredi Desteği" />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet10} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Salon stor fon perde ve ip perde çalışması</h3>
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Salon sacaklı tül perde ve rustikli fon perde çalışması</h3>
            </div>

            <div className={`${styles.right} image`}>
              <img src={hizmet11} alt="Sıfır Faizli Kredi Desteği" />
            </div>
          </div>

          <div className={styles.row}>
            <div className={`${styles.left} image`}>
              <img src={hizmet12} alt="Girişimcilik Desteği" />
            </div>

            <div className={`${styles.right} text`}>
              <h6>İthal tasarımlar</h6>
              <h3>Avangard salon çalışması</h3>
            </div>
          </div>


        </section>        

        <h2>Prensiplerimiz</h2>
        <ParallaxSection />

        <section className={styles.testimonial}>
          <h2>Referanslarımız</h2>
          <p>
            Hizmet verdiğimiz arkadaşlardan almış olduğumuz geri bildirimler
          </p>

          <div className={styles.embla}>
            <div className={styles.embla__viewport} ref={emblaRef}>
              <div className={styles.embla__container}>
                <div className={styles.embla__slide}>
                  <div className={styles.embla__slide__number}>
                    <span>1</span>
                  </div>

                  <div className={styles.cardContainer}>
                    <Card cardClass={styles.card}>
                      <h5>
                      Salonumun mimarisine uygun anahtar teslim <strong>perde montajı</strong> için çok teşekkür ederim.
                      </h5>

                      <div className={styles.bottom}>
                        <div className={styles.left}>
                          <StarsRating
                            value={5}
                            classNamePrefix={styles.starsrating}
                          />
                          <h6>5.00 / 5.00</h6>
                        </div>
                        <div className={styles.right}>
                          <h4>Lütfiye Savaş</h4>
                          <h4>Avukat</h4>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>

                <div className={styles.embla__slide}>
                  <div className={styles.embla__slide__number}>
                    <span>1</span>
                  </div>

                  <div className={styles.cardContainer}>
                    <Card cardClass={styles.card}>
                      <h5>
                        Çok teşekkürler ustam, sayenizde hayallerimdeki
                        salona kavuştum. Sizin gibi güvenilir bi ustaya
                        rastladığım için çok şanslıyım, size güvenimi boşa
                        çıkarmadınız, ne desem az kalır..
                      </h5>

                      <div className={styles.bottom}>
                        <div className={styles.left}>
                          <StarsRating
                            value={4.5}
                            classNamePrefix={styles.starsrating}
                          />
                          <h6>4.50 / 5.00</h6>
                        </div>
                        <div className={styles.right}>
                          <h4>Aslı Deniz Töre</h4>
                          <h4>Ev Hanımı</h4>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>

                <div className={styles.embla__slide}>
                  <div className={styles.embla__slide__number}>
                    <span>1</span>
                  </div>

                  <div className={styles.cardContainer}>
                    <Card cardClass={styles.card}>
                      <h5>
                        Tam istediğim gibi oldu. Allah sizden de razı olsun abi!
                      </h5>

                      <div className={styles.bottom}>
                        <div className={styles.left}>
                          <StarsRating
                            value={5}
                            classNamePrefix={styles.starsrating}
                          />
                          <h6>5.00 / 5.00</h6>
                        </div>
                        <div className={styles.right}>
                          <h4>Ceren Tosun</h4>
                          <h4>Ev Hanımı</h4>
                        </div>
                      </div>
                    </Card>
                  </div>
                </div>
              </div>

              <div className={styles.embla__dots}>
                {scrollSnaps.map((_, index) => (
                  <DotButton
                    key={index}
                    selected={index === selectedIndex}
                    onClick={() => scrollTo(index)}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>

        <section className={`${styles.location} location`}>
          <h2>Bize Ulaşın</h2>
          <div className={styles.inner}>
            <h2>Adresimiz</h2>
            <iframe title="adres" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.800491238282!2d32.72417307525565!3d39.968213082816746!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d349d1a472b0f9%3A0xe32440a374a76cc8!2zQmF0xLFrZW50!5e0!3m2!1str!2str!4v1695675084901!5m2!1str!2str" width="600" height="450" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </section>
        
        <ScrollTopButton />
      </div>
    </div>
  )
}

export default Home
