import React, { useState } from "react"
import styles from "./FaqItem.module.scss"

const FaqItem = ({question, answer}) => {
  const [isShowing, setIsShowing] = useState(false)

  const toggle = () => {
    setIsShowing(!isShowing)
  }


  return (
    <>
        <div className={styles.faqItem}>
            <div className={styles.container}>
                <button className={styles.button} onClick={toggle} type="button">
                    <div className={styles.topRow}>
                        <p><b></b>{question}</p>
                        {/* <BsChevronDown className={isShowing ? styles.upArrow : styles.downArrow } /> */}
                        <span aria-expanded={isShowing ? true : false} role="button" className={isShowing ? `${styles.expandIcon} ${styles.active}` : styles.expandIcon}></span>
                    </div>
                    <p className={styles.answer} 
                    style={{ display: isShowing ? "block" : "none", color: "black" }}
                    >
                    {answer}
                    </p>
                </button>
            </div>
        </div>
    </>
  )
}

export default FaqItem
