import React from "react"
import styles from "./Hamburger.module.scss"

const Hamburger = ({onClick, showMenu}) => {

  return (
    <button className={showMenu ? `${styles.hamburgerContainer} ${styles.active}` : styles.hamburgerContainer } onClick={onClick} >
      <span className={styles["screen-reader"]}>Menu</span>
      <span className={styles["hamburger"]}>
        <span className={styles["hamburger-inner"]}></span>
      </span>
    </button>
  )
}

export default Hamburger
