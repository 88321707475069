import React from 'react'
import styles from "./ParallaxSection.module.scss"
import perdetemizligi from "../../assets/images/perde-temizligi.jpg"
import stortemizligi from "../../assets/images/stor-perde-temizligi.webp"
import hizliteslimat from "../../assets/images/hizli-teslimat.png"
import kornis from "../../assets/images/kornis.jpg"
import { IoStarSharp } from "react-icons/io5"

const ParallaxSection = () => {
  return (
    <section className={styles.parallaxSection}>
        <div className={styles.container}>
            <div className={`${styles.split} secondSection`}>
                <div className={styles.row}>
                    <div className={`${styles.left} text`}>
                    <h3>Garantili Temizlik</h3>
                    <p>
                    Tül, Perde ve Tüm Stor Çeşitleri Garantili Bir Şekilde Temizliği Yapılırken İlk Gün ki Görünüm Sağlanır.
                    </p>
                    </div>

                    <div className={`${styles.right} image`}>
                        <img src={perdetemizligi} alt="Perde temizliği" />
                    </div>
                </div>
                
                <div className={styles.row}>
                    <div className={`${styles.left} image`}>
                        <img src={stortemizligi} alt="Stor perde" />
                    </div>

                    <div className={`${styles.right} text`}>
                    <h3>Kaliteli Hizmet</h3>
                    <p>
                    Referanslarımız ve Gerek Tüm Hizmetlerimiz İle Sizlere En Kaliteli Hizmeti Sunuyoruz !
                    </p>
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={`${styles.left} text`}>
                    <h3>Hızlı Teslimat Garantisi</h3>
                    <p>
                    İşimizi En Hızlı Şekilde Belirlenen Tarihte Sizlere Teslimatını Sağlarken de Ürünlerimizin Garantisini de Sağlamaktayız.
                    </p>
                    </div>

                    <div className={`${styles.right} image`}>
                        <img src={hizliteslimat} alt="Hızlı teslimat" />
                    </div>
                </div>

                <div className={styles.row}>
                    <div className={`${styles.left} image`}>
                    <img src={kornis} alt="Korniş" />
                    </div>

                    <div className={`${styles.right} text`}>
                        <h3>Stor-Perde-Mefruşat-Korniş Hizmetleri</h3>
                        <div className={styles.iconset}>
                            <IoStarSharp size={30} color='gold'/>
                            <IoStarSharp size={30} color='gold'/>
                            <IoStarSharp size={30} color='gold'/>
                            <IoStarSharp size={30} color='gold'/>
                            <IoStarSharp size={30} color='gold'/>
                        </div>
                        <p>
                        Çağlayan Stor Olarak Sizlere Daha İyi Hizmet Verebilmek İçin Her Zaman Kendimizi ve Hizmetlerimizi Yeniliyoruz.
                        <br /><br />
                        Yerinde Hizmet ve Ürün Kalitesi İlkesi İle İşimizin En İyisiyiz.
                        </p>
                    </div>
                </div>

            </div>
            
        </div>
    </section>
  )
}

export default ParallaxSection