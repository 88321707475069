import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from "./BlogMainpage.module.scss"
import { BiPhoneCall } from "react-icons/bi"
import bg from "../../assets/images/bg.jpg"
import moon from "../../assets/images/moon.png"
import mountain from "../../assets/images/mountain.png"
import road from "../../assets/images/road.png"
import { Link } from 'react-router-dom'
import Card from '../../components/card/Card'
import Pagination from '../../components/pagination/Pagination'
import { Seo } from '../../components/seo/Seo'
import Footer from '../../components/footer/Footer'
import { cardsManual, townsAnkara, townsAnkaraEnglish, townsAntalya, townsAntalyaEnglish, townsAydin, townsAydinEnglish, townsBursa, townsBursaEnglish, townsIstanbul, townsIstanbulEnglish, townsIzmir, townsIzmirEnglish, townsMugla, townsMuglaEnglish } from '../../assets/data/TownsData'
import { useDispatch, useSelector } from 'react-redux'
import { SET_FIRST_RENDER, SET_cardsAll, selectCardsAll, selectCurrentPage, selectFirstRender } from '../../components/redux/slice/navSlice'

const BlogMainpage = () => {
    
    // const textRef = useRef(null)

    const bgRef = useRef(null)
    const roadRef = useRef(null)
    const moonRef = useRef(null)
    const mountainRef = useRef(null)  



    
    // const [nav, setNav] = useState("home")
    
    const cardsContainerRef = useRef(null)

    useEffect(() => {
      document.addEventListener("scroll", handleScroll)
      return () => {
        document.removeEventListener("scroll", handleScroll)
      }
    }, [])


    const handleScroll = () => {
        
        if(window.location.href.includes("blog"))
         {
            // console.log(bgRef)
            // bgRef.current.style.transform = "scale(" +(1+ (window.scrollY * .001)) + ")"
            // moonRef.current.style.left = -window.scrollY * 0.5 + "px"
            // moonRef.current.style.transform = "scale(" +(1+ (window.scrollY * .001)) + ")"
            // mountainRef.current.style.top = window.scrollY * 1 + "px"
            // mountainRef.current.style.transform = "scale(" +(1+ (window.scrollY * .002)) + ")"
            // mountainRef.current.style.transformOrigin = "bottom"
            // roadRef.current.style.top = window.scrollY * 1 + "px"
            // roadRef.current.style.transform = "scale(" +(1+ (window.scrollY * .001)) + ")"

            
            bgRef.current.style.transform = "scale(" +(1+ (window.scrollY * .001)) + ")"
            moonRef.current.style.left = -window.scrollY * 0.5 + "px"
            moonRef.current.style.transform = "scale(" +(1+ (window.scrollY * .003)) + ")"
            mountainRef.current.style.top = `calc(${window.scrollY * .3 + "px"})`
            mountainRef.current.style.transform = "scale(" +(1+ (window.scrollY * .002)) + ")"
            mountainRef.current.style.transformOrigin = "bottom"
            roadRef.current.style.top =  `calc(${window.scrollY * 1 + "px"})`
            roadRef.current.style.transform = "scale(" +(1+ (window.scrollY * .003)) + ")"
            mountainRef.current.style.transformOrigin = "bottom"
        }
        
        // console.log(window.scrollY)
        
        // console.log(oldScroll > paraxRef.current.current)
        // console.log(paraxRef.current.current);
      }

    // const scrollToTop = () => {
    //     document.getElementById('paraxContainer').scrollTo({top: 0})
    //     // window.scrollTo({top:0, behavior:'smooth'})
    // }


    

const CardComp = ({imgName, title, link }) => (
    <Card key={link} cardClass={`${styles.card} webDesignCard`}>
        <div className={styles.imgContainer} key={title}>
            <img src={`/images/${imgName}`} alt={imgName} />
        </div>
        <h2><strong>{title}</strong></h2>
        {/* <Link to={`${link}`}>İncele</Link> */}
        <Link to={`${link}`} state={{imageUrl: `/images/${imgName}`, title: title}}>İncele</Link>
    </Card>
)


const cardsManualfromData = cardsManual.map((card, i)=>(
    <CardComp key={card.key} imgName={card.imgName} title={card.title} link={card.link} />
))
 

const townsAnkaraCards = townsAnkara.map((town, i)=>(
    <CardComp key={town} imgName={`ankara-web-tasarim.jpg`} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsAnkaraEnglish[i]}-web-tasarim`} />
))

const townsIstanbulCards = townsIstanbul.map((town, i)=>(
    <CardComp key={town} imgName={"istanbul-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsIstanbulEnglish[i]}-web-tasarim`} />
))

const townsIzmirCards = townsIzmir.map((town, i)=>(
    <CardComp key={town} imgName={"izmir-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsIzmirEnglish[i]}-web-tasarim`} />
))

const townsAydınCards = townsAydin.map((town, i)=>(
    <CardComp key={town} imgName={"aydin-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsAydinEnglish[i]}-web-tasarim`} />
))

const townsMuglaCards = townsMugla.map((town, i)=>(
    <CardComp key={town} imgName={"mugla-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsMuglaEnglish[i]}-web-tasarim`} />
))

const townsAntalyaCards = townsAntalya.map((town, i)=>(
    <CardComp key={town} imgName={"antalya-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsAntalyaEnglish[i]}-web-tasarim`} />
))

const townsBursaCards = townsBursa.map((town, i)=>(
    <CardComp key={town} imgName={"bursa-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={`/${townsBursaEnglish[i]}-web-tasarim`} />
))

// const townsBursaCards = townsAnkara.map((town, i)=>(
//     <CardComp key={town} imgName={"ankara-web-tasarim.jpg"} title={`${town.charAt(0).toUpperCase() + town.slice(1)} Web Tasarım`} link={"/web-tasarim-ankara"} />
// ))


  // eslint-disable-next-line no-extend-native
  Array.prototype.shuffle = function() {
    let m = this.length, i;
    while (m) {
      i = (Math.random() * m--) >>> 0;
      [this[m], this[i]] = [this[i], this[m]]
    }
    return this;
  }

// const cardTowns = 

const firstRender = useSelector(selectFirstRender)
const dispatch = useDispatch()
const cardTowns = [...townsAnkaraCards,...townsIstanbulCards, ...townsIzmirCards, ...townsAydınCards, ...townsMuglaCards, ...townsAntalyaCards, ...townsBursaCards].shuffle()
const cardsAll = useSelector(selectCardsAll)

useEffect(()=>{
    // console.log("cardsAll",cardsAll);
    if(firstRender){
        dispatch(SET_FIRST_RENDER(false))
        dispatch(SET_cardsAll([...cardsManualfromData, ...cardTowns]))
    }
}, [])






  // pagination states:
  const [currentPage, setCurrentPage] = useState(useSelector(selectCurrentPage))
  const [productsPerPage] = useState(9)
  const totalNumberOfProducts = cardsAll?.length
  // get current products index number:
  const indexOfLastProduct = currentPage * productsPerPage
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage
  const [currentProducts, setCurrentProducts] = useState(null)


  useEffect(()=> {
    setCurrentProducts(cardsAll?.slice(indexOfFirstProduct, indexOfLastProduct))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[currentPage, cardsAll])

  
  

  return (
    <div className={styles.sectionParallax} style={{maxWidth:"100vw", overflow:"visible"}}>
    

            <Seo
                title="Blog | Bubi Yazılım | Web & Mobil Tasarım "
                description="Siz hayal edin, biz yapalım.."
                keywords="Ankara Web Tasarım, Ankara Web Sitesi yaptır, Web tasarımı, kurumsal yazılım, website yapımı, web site fiyat, mobil uygulama, logo tasarımı, web sitesi oluştur"
            />

                    {/* <div className={styles.sectionParallax}> */}
                    <div className={styles.imageGroup} style={{maxWidth:"100vw", overflow:"hidden"}}>
                        <img src={bg} id='bg' alt='' ref={bgRef} className={styles.bg} />
                        <img src={moon} id='moon' alt='' className={styles.moon} ref={moonRef} />
                        <img src={mountain} id='mountain' alt='' ref={mountainRef} className={styles.mountain} />
                        {/* <h2 id='text' ref={textRef}>Çok yakında buradan paylaşılacaktır.</h2> */}
                        <img src={road} id='road' alt='' ref={roadRef} className={styles.road} /> 
                    </div>                      
                    {/* </div> */}
                    
                    <section className={styles.sectionBody}>                        
                        <div className={styles.niceButton} onClick={()=>setCurrentPage(1)}>BLOG
                            <svg>
                                <rect x={0} y={0} fill='none' width={"100%"} height={"100%"}></rect>
                            </svg>
                        </div>


                        <div className={styles.cardsContainer} ref={cardsContainerRef}>
                            {currentProducts}
                        </div>

                        <div className={styles.paginationContainer}>                 

                            <Pagination
                                currentPage = {currentPage}
                                setCurrentPage = {setCurrentPage}
                                productsPerPage = {productsPerPage}
                                totalNumberOfProducts = {totalNumberOfProducts}
                            />

                        </div>

                        <div style={{position:"absolute", display:"none"}}>
                            {cardsAll}
                        </div>
                        
                </section>

        {/* <Footer position={"relative"} />   */}
    </div>
  )
}

export default BlogMainpage