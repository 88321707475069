import React from "react";
import styles from "./Footer.module.scss";
import FooterSocial from "./footerSocial/FooterSocial";
import { Link } from "react-router-dom";
import logoBubi from "../../assets/images/logoBubi.png"

const date = new Date();
const year = date.getFullYear();

const Footer = () => {
  return (
    <div className={styles.footer}>
        <div className={styles.left}>
          <p>&copy; {year} Tüm hakları saklıdır.</p>
          <p>Mesa, 06370 Batıkent Yenimahalle/Ankara, Türkiye</p>
          {/* <p>+905052575886</p> */}
        </div>
        <div className={styles.middle}>
          <Link to="https://bubiyazilim.com" target="_blank"><p>Designed by &nbsp;</p><img src={logoBubi} loading="lazy" alt="Bubi Yazılım | Web & Mobile Design" title="Bubi Yazılım | Web & Mobile Design" /></Link>
        </div>
        <FooterSocial />
    </div>
  )
}

export default Footer;
