import React from 'react'

function useHeadings() {
    const [headings, setHeadings] = React.useState([]);
    React.useEffect(() => {
      const elements = Array.from(document.querySelectorAll("h2, h3, h4, h5, h6"))
        .filter((element) => element.id)
        .map((element) => ({
          id: element.id,
        //   id: id,
          type: element.localName,
          text: element.textContent ?? "",
          level: Number(element.tagName.substring(1))
        }));
      setHeadings(elements);
    }, []);
    return headings;
  }
  
  export const TableOfContent = () => {
    const onHeaderClick = (e) => {
        // e.scrollIntoView()
        document.getElementById(e).scrollIntoView() 
    }
    const headings = useHeadings()
    const activeId = useScrollSpy(
        headings.map(({ id }) => id),
        { rootMargin: "0% 0% -25% 0%" }
      );
    let x = 0
    let y = 0
    return (
      <nav style={{ top: '1em', right: '1em', padding: "2em", margin:"2em", border:"2px solid wheat", backgroundColor:"rgba(0,0,0,0.8)" }}>
        <span style={{fontSize:"calc(min(7vw, 1.3rem))", color:"wheat"}}>İçindekiler</span>
        <ul>
          {headings.map((heading, i) => { 
            x = (heading.type === "h2") ? x + 1 : x
            y = (heading.type === "h3") ? y + 1 : y
            if(headings[i-1]?.type !== headings[i].type) y = 1
            return (<li key={heading.id} style={{ marginLeft: `${heading.level - 2}em`, textAlign:"left", fontSize:"calc(min(6vw, 1rem))" }}>
                {heading.type === "h2" ? `${x}. ` : heading.type === "h3" ? `${x}.${y}. ` : `${x}.${y-2}. `}
              <a href={`#${heading.id}`} style={{backgroundColor:"transparent"}}>{heading.text}</a>
            </li>)            

    })}
        </ul>
      </nav>
    )
  }

  export function useScrollSpy(
    ids,
    options
  ) {
    const [activeId, setActiveId] = React.useState();
    const observer = React.useRef();
    React.useEffect(() => {
      const elements = ids.map((id) =>
        document.getElementById(id)
      );
      observer.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry?.isIntersecting) {
            setActiveId(entry.target.id);
          }
        });
      }, options);
      elements.forEach((el) => {
        if (el) {
          observer.current?.observe(el);
        }
      });
      return () => observer.current?.disconnect();
    }, [ids, options]);
    return activeId;
  }


  const getId = (children) => {
    return children.toString()?.split(" ")?.map((word) => word.toLowerCase())?.join("-")?.replace("?", "")
  }
  
  export const Heading = ({ children, id, as: Element, ...props }) => {
    const theId = id ?? getId(children);
    return (
      <Element id={theId} {...props}>
        {children}
      </Element>
    );
  }