import React, { useEffect } from "react"
import styles from "./SeoNedir.module.scss"
import { Link, useLocation } from "react-router-dom"
import webdesignFoto from "../../../assets/images/mobil-uyumlu-web-tasarim.png"
import haritaTurkey from "../../../assets/images/haritaTurkey.png"
import {
  townsEnglish,
  cardsManual
} from "../../../assets/data/TownsData"
import { Seo } from "../../../components/seo/Seo"
import Footer from "../../../components/footer/Footer"
import { Heading, TableOfContent } from "../../../components/customhooks/useHeadings"
import Card from "../../../components/card/Card"
import FaqItem from "../../../components/faq/FaqItem"

const SeoNedir = ({title, imgName}) => {
  const allCards = cardsManual

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  // eslint-disable-next-line no-extend-native
  Array.prototype.shuffle = function() {
    let m = this.length, i;
    while (m) {
      i = (Math.random() * m--) >>> 0;
      [this[m], this[i]] = [this[i], this[m]]
    }
    return this;
  }

  const CardComp = ({imgName, title, link }) => (
    <Card key={link} cardClass={`${styles.card} webDesignCard`}>
        <div className={styles.imgContainer} key={title}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
        </div>
        <h2><strong>{title}</strong></h2>
        <Link to={`${link}`} state={{imageUrl: `/images/${imgName}`, title: title}} onClick={()=>window.scrollTo({ top: 0, behavior: "smooth" })}>Devamı ⯈</Link>
    </Card>
)


const CardsManualfromData = cardsManual.filter((card)=> card.title !== title).shuffle().slice(0, 6).map((card, i)=>(
    <CardComp key={card.key} imgName={card.imgName} title={card.title} link={card.link} />
))

  return (
    <div className={styles.container}>
      <Seo
        title={`${title} | Web Tasarım | Bubi Yazılım `}
        description="Siz hayal edin, biz yapalım.."
        keywords="Web sitesi fiyatları, 2023 yılı web sitesi fiyatı, web tasarım fiyatı"
      />
      <h1>{title}</h1>
      <section className={styles.splitSection}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
          </div>
          <p>
            “{title}" sorusuna tek bir kısa cevap verecek olursak; Hedeflenen kelimelerde var olan sıralamalardan daha üst sıralara çıkmak için yapılan çalışmanın bir bütünü diyebiliriz. SEO hizmeti bir web sitesinin tüm metrikleri göz önüne alarak en iyi içerik, en iyi görünüm ve en hızlı site performansına ulaşma sanatıdır. SEO olarak bilinen ve Türkçe manası "arama motoru optimizasyonu" olan hizmetin değeri her geçen gün artmaktadır. 
          </p>

          <TableOfContent />

          <Heading as="h2">
            SEO Nedir?
          </Heading>
          <p>SEO teriminin günümüzde tanımı Web sitesindeki iyileştirmeler olarak tanımlansa da hedef alanı ve geliştirmeleri her geçen gün genişlemektedir. İnternet kullanıcısının artan beklentileri ve gelişen teknoloji ile birlikte SEO kriterleri değişken gösteriyor. Bizler de SEO ajansı olarak bu kriterlere en uygun web site mimarını oluşturmak için çalışmalar yapıyoruz. Peki, neden SEO Çalışmasına ihtiyaç duyuluyor? SEO çalışması web sitesi sahipleri ve firma sahiplerinin sektörlerindeki en yüksek ziyaretçi ve müşteri sayısına ulaşılması için yapılır.</p>
          
          <Heading as="h2">
            İç SEO Nedir?
          </Heading>
          <div className={styles.imgContainer}>
            <img src={`/images/question.jpg`} alt={imgName} loading="lazy"/>
          </div>
          <p>
          Bir web sitenin temelinin oluşturulması tarafında yapılan çalışmaların bütüne İç SEO denir. Bu süreçte profesyonel birtakım SEO araçları kullanılır. Ahrefs, Deepcrawl , Botify gibi araçlar eşliğinde web sitesinin teknik hataları ve web site performansı incelenir. Bu incelemeler sonucunda birtakım iyileştirme önerileri ve uygulamaları sunulur. İç SEO yapılmasındaki en büyük amaç web sitesinin Google tarafından daha iyi anlaşılmasına yani indexlenmesine daha kolay imkan sağlamak ve gün içinde Google’ ın web sitesinde daha çok sayfa taramasına(crawl) etmesine olanak sağlamaktadır.
          </p>
          
          <Heading as="h2">Site Dışı SEO Nedir?</Heading>
          

          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/${imgName}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            Bir web sitenin İç SEO kısmındaki teknik problemleri çözümledikten sonra yapılması gereken imaj ve otorite arttırma çalışmalarına Site Dışı SEO (Offpage SEO) denir. Dış seo çalışmaları ile web sitesinin Google gözünde otorite kazanması için güvenilir link kaynaklarından backlink almasıyla mümkündür. Bu backlinkler tamamen siteleri üst sıralara taşımak ve Google gözünde daha fazla prestij kazanmak adıyla firmalara önerilir.
          </p>
          <Heading as="h2">SEO Sürecinde <Link to={"/"} target="_blank">Bubi Yazılım</Link> Olarak Neler Yapıyor?</Heading>
          <Heading as="h3">Kelime Analizi</Heading>
          <p>
          SEO’ nun temel taşlarından biri olan kelime analizi SEO çalışmasına başlarken ilk yapılan adımlardan biridir. Çalışma yapılacak web sitesine dönüşüm getirebilecek kelimelerin araştırılması ve tespit edildiği aşamadır. Kelime analizi sürecinde Google servisleri ve Premium SEO araçları kullanılıyor. Egegen olarak bizler de kelime analizinde Google Keyword Planner, Semrush, Kwfinder, Ahrefs gibi SEO araçları kullanmaktayız.
          </p>
          <Heading as="h3">Rakip Analizi</Heading>
          <p>
          Sektörel kelime araştırmalarını tamamladıktan sonraki hedefimiz Rakip Analizi çalışmamızı yapmak oluyor. Web sitemizin hedeflediği kelimelerde kaçıncı olduğu ve rakiplerimizin bu anahtar kelimelerde kaçıncı sırada olduğuna dair notlarımız alınır. Rakiplerden eksi ve artı yönlerimiz S.W.O.T analizi eşliğinde belirlenir. Sitenin tüm sayfa kelimelerine özel  çözümler üretilmeye başlanır. Rakiplerin var olan trafiklerini, backlinklerini ve en iyi sayfalarına dair yorumlamalar yapabilmek için: Ahrefs, Smilarweb ve Ranksignals gibi araçları kullanabilirsiniz.
          </p>
          <Heading as="h3">Site Analizi</Heading>
          <p>
          Site Analizi kısmında çalışma yapılan web sitesinin tüm sayfalarının SEO kriterleri değerlendirilir. Eksik veya yanlış yapılan uygulamalar düzeltilmeye başlanır. Örnek vermek gerekirse web sitesinin title , description, heading etiketleri , mobil uyumluluk , site hızı gibi tüm metrikleri tek tek değerlendirilir. Bu süreci Screaming Frog, Botify ve Search console gibi servisler üzerinden sürdürüyoruz.
          </p>
          <Heading as="h3">Site İçi Optimizasyon </Heading>
          
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/yazilim.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
          Google’ın bir web sitesini daha iyi anlamlandırabilmesi için bilinen 200 farklı kritere uyulması gerekmektedir. Bunlar Site hızı, title tag uyumluluğu , description gibi kriterlerden oluşmaktadır. Google bu kriterleri en iyi uygulayan web sitesini üst sıralara çıkarmaktadır. Egegen olarak bizler de web tasarımını yaptığımız siteleri İÇ SEO kaygısı güderek yapıyoruz.  Oluşturulan sayfa, kullanıcı deneyimine uygun ve web sitesinde vakit geçirirken hiçbir problemle karşı karşıya gelmemesine özen gösteriyoruz. Bu işlemleri yaparken uzman içerik editörlerimiz tarafından sayfaya en kaliteli içerikleri hazırlıyoruz. İçerik kısmı Google için en önemli kriterlerden biridir. Çok sayıda içerik üretmektense az sayıda kaliteli içerik üretmeyi web sitesi sağlığı açısından daha faydalı bulmaktadır.
          </p>
          <Heading as="h3">Site Dışı SEO (Offpage SEO)</Heading>
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/callcenter.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
          Site Dışı SEO kısmı en az İç SEO kadar önemli ve uzmanlık gerektiren bir konudur. Tamamen site dışından yapılan müdahalelerden oluşmaktadır. Daha üst sıralara çıkmanızı kolaylaştıran temel çalışmalardan biridir. Ancak bu çalışmada her yiğidin yoğurt yiyişi farklıdır tabirini kullanabiliriz. Bazı SEO uzmanları Anchor Text odaklı backlinkler odaklanırken bazı SEO uzmanları naked link tarzı çalışmalar yapmaktadır. Bu tamamen SEO uzmanının tercihine ve uzmanlığına kalmış bir durumdur. Ancak herkes tarafından kabul edilen en önemli kıssas web sitesini en otoriter sitelerden refere edecek linkleri toplayabilmektir. Backlink alımları yaparken daha önceki adımlarımızdan biri olan Rakip analizi bilgilerinden yararlanabilir rakiplerimizin link aldığı veya link verdiği siteler takip edilebilir. O yerlerden link alımları gerçekleştirilebilir. Ancak burada dikkat etmeniz gereken tek kriter tercihinizi  kaliteli sitelerden yana kullanmak olmalıdır.
          </p>

          <Heading as={"h2"}>SEO Hizmeti Almadan Önce Sıkça Sorulan Sorular</Heading>
          <FaqItem question="SEO hizmeti gerekli midir?" answer="SEO hizmeti sosyal medya pazarlaması, içerik pazarlaması gibi dijital pazarlamanın kanallarından biridir. SEO çalışmaları daha çok e-ticaret projeleri, kurumsal firmalara , haber siteleri ve trafik kaygısı güden sitelere gerekmektedir. Uzman ekipler tarafından yapıldığında olumlu sonuçlara yol açmaktadır. İlgili anahtar kelimeler kelime analizi eşliğinde tespit edilerek ilk sayfaya gelmek için yapılan çalışmanın sonucudur." />
          <FaqItem question="SEO mu SEM mi?" answer="Bu soruya doğrudan SEO veya SEM diyemeyiz çünkü tamamen belirlediğiniz KPI’lara göre hareket etmeniz sizler için çok faydalı olacaktır. Sizlere şöyle bir yöntem önerebiliriz: Sizler için dönüşüm getiren ancak çok yüksek maliyetlere bunu sağlayan kelimeleri listeleyerek bu kelimelere SEO çalışması yapılabilir. Böylece siteye dönüşüm getirecek kelimeyi daha ucuz maliyetle elde etme fırsatı bulunabilir." />
          <FaqItem question="Kullanıcı Deneyimi (UX)'in SEO ile ilişkisi var mı?" answer="Bir web tasarım firması olarak UX’in değerini çok iyi biliyoruz. UX ile SEO artık birbirinden ayrılmaz bir bütün haline gelmektedir. Bu kanıyı da Google her geçen gün doğrulamaktadır. Özellikle Site içi SEO tarafında çok önemli metriklerden biri olan site hızı , kullanıcının istediği bilgiye veye alışveriş olayına en hızlı bir şekilde ulaşması SEO’ yu da olumlu yönde etkilemektedir." />
          <FaqItem question="SEO ajansını seçerken nelere dikkat etmeliyiz?" answer="Hayatında ilk defa SEO ajansı seçecek biri için bu süreç oldukça zordur. Sitenizi incelemeden fiyat veren firmalardan ve sizlere 0 garanti veren ajanslardan uzak durmanızı tavsiye ediyoruz. Çünkü sitenizin durumunu incelemeden sitenize herhangi bir yol haritası çıkarmadan fiyat belirtmek çok profesyonel kalmaz. Çalışacağınız SEO ajansı her müşteriye özel yol haritası ve çözüm reçetesi sunar. Hasta - doktor ilişkisine benzetebiliriz bu durumu. Her sitenin hastalığı ve bu hastalığı çözecek ilaç aynı değildir. O yüzden kalıp ve paket satan SEO firmalarından uzak durunuz." />
          <FaqItem question="SEO aksiyonlarını düzenli olarak raporluyor musunuz?" answer="Bubi Yazılım firması olarak müşterilerimize yapılan aksiyonlar belli periyotlarda rapor olarak sunulmaktadır. Ayrıca kendilerinin bireysel talep etmeleri durumunda güncel sıralama raporları, teknik analiz raporlarının hepsi firma yetkilisine mail olarak iletilmektedir." />
          <FaqItem question="SEO hizmetinde en popüler kullanılan araçlar nelerdir?" answer="SEO Ajansları kullanım amacına göre SEO araçları kullanmaktadırlar. Örneğin Site içi SEO kısmı için: Botify , Screaming frog ve Deepcrawl olabiliyorken. Dış SEO : Ahrefs, Semrush, SimilarWeb gibi" />
          <FaqItem question="SEO çalışmasında yazılım ekibine ihtiyaç duyulur mu?" answer="Bir web sitesinin çok iyi bir şekilde taranabilmesi için öncelikle okunabilir kodlar ile yazılması ve hızlı açılan web sayfalarına sahip olması gerekir. SEO Çalışması yaparken site içi hızlandırma olsun, teknik problemler olsun birçok detaylarda yazılım departmanı ile işbirlikleri olabiliyor." />
          <FaqItem question="Sadece site içi SEO yaptırmak yeterli midir?" answer="Sadece Site içi SEO yaparak web sitesini üst sıralara taşımanız mümkün olmayabilir. Rekabeti yüksek alanlarda Dış SEO yani backlink desteği almanız gerekmektedir. Çünkü rakipleriniz otorite farkını Backlinkler aracılığıyla açarken sizlerinde rakiplerinizi takip ederek bu fırsatlardan yararlanmanız gerekmektedir." />
          <FaqItem question="Ne kadar süre SEO hizmeti almalıyım?" answer="SEO hizmeti düzenli ve kısa süreli bir hizmet çeşiti değildir. Rekabet türüne göre sitenizin var olan durumuna göre oluşabilecek stratejilerden yola çıkarak oluşturulduğundan farklılık göstermektedir. Yani her firmaya aynı sürede SEO hizmeti verilmemektedir." />
          <FaqItem question="Organik trafik ne anlama gelir?" answer="Organik trafik bir web sitesinin herhangi bir ücret ödemeden tamamen arama motoru aracılığıyla siteye getirdiği trafiğe denmektedir. Bu trafik tamamen SEO çalışması aracılığıyla getirilmektedir. SEO hizmeti ne kadar başarılı olursa var olan potansiyel trafik o kadar da artar." />
          <FaqItem question="SEO hizmetinde başarı garantisi veriyor musunuz?" answer="SEO çalışmasında ilk sıra garantisi ve kelime garantisi verilmemektedir. SEO çalışmalarında tek başarı kriteri SEO ajansının gösterdiği performans ile değerlendirilmemelidir. SEO ajansları daha çok bilgilendirme ve brief vermekle sorumludur. Verilen öneriler ne kadar hızlı ve etkili bir şekilde yapılırsa sitenizin performansı da o kadar hızlı yükselir. Gün sonunda yapılan çalışmalar Google tarafından değerlendirileceği için başarı garantisi verilmesi sağlıklı olmayacaktır." />
          <FaqItem question="Manual Spam - Negatif SEO konusunda neler yapıyorsunuz?" answer="SEO Ajanslarının yanlış aksiyonları veya Freelancer SEO uzmanları tarafından kasten veya bilinçsizçe yapılan hamleler sonucu web sitemiz Negatif SEO veya Manuel Spam ile karşı karşıya kalmış olabilir. Bu süre zarfında yaptığımız işlemler aşağıdaki gibidir:

Web sitesini site içi ve site dışı olmak üzere 2 türlü audit işlemi yapılır. Bu audit işlemimiz 3-5 SEO aracı ile birlikte yapılıp en geniş bilgi ve veri kümesine ulaşılır. Doğal olmayan site içi link yapısı ve dış link yapısı tespitleri yapılır. Site içinde spamsal iç linklemeler varsa bu iç linkleri  doğal iç link yapısına çeviriyoruz.  Daha sonrasında dış link yapısı incelenir. Sektörün haricinde verilen anchor text yoğunlukları tespit edilir veya düşük profilli backlinkler yani sitenin otoritesini düşürebilecek linkler listelenerek disavow list dosyasına eklenerek Search Console üzerinden Google'a bildirilir. Böylece çalışmayı  link reddetmesi ve doğal iç link toparlamasıyla tamamlanır." />
          <FaqItem question="SEO fiyatları neye göre belirleniyor?" answer="SEO fiyatları sektör ve kelime rekabetine göre değişkenlik göstermektedir. Örnek vermek gerekirse İzmir SEO kelimesi ile SEO kelimesinin Google’ da ilk sayfaya getirme maaliyeti ve orada harcanacak efor çok farklıdır. Bireysel ve SEO firmalarının fiyat belirlerken sizlere çalışılması istenilen web sitesini ayda kaç saat çalışarak istenilen noktaya getiririm hesaplaması yapmanız. Daha sonrasında ise çalışacağınız saat X saat başı ücretiniz ile müşterilerinize çalışma fiyatınızı iletebilirsiniz." />
          <FaqItem question="SEO uyumlu içeriklerde nelere dikkat ediyorsunuz?" answer="Content is King anlayışı ile yola çıkarak SEO’nun temel taşlarından biri olan Content yani içerik çok önemli bir yere sahiptir. Bizler içerik editörlerimize içerik briefingi verirken kelime odaklı bir analiz vermeyiz. Sayfa odaklı iyileştirmeler ve geliştirmeler vermekteyiz. Kullanıcının anlayabileceği kaliteli ve özgün içeriklere odaklanıyoruz. Semantik ve NLP gibi son dönemlerde baya popülerliği artan gelişmeleri de yakından takip ediyoruz." />
          <FaqItem question="Sadece SEO yaparak tıklamalarımı artırabilir miyim?" answer="Başarılı bir SEO çalışması dönüşümlerinizi arttırabilir ancak yeterlidir diye kesinlikle düşünmeyin. SEO sadece dijital pazarlamanın alt dallarından biridir. Facebook , Instagram, Google ads ve yeniden pazarlama metrikleri gibi gelen trafikleri en iyi bir şekilde işleyerek satışlarınızı çok ciddi oranlarda arttırabilirsiniz. Örnek vermek gerekirse organik olarak trafik almış sayfalarınıza gelen müşteriye Facebook ve Google Ads üzerinden de reklamlar vererek onları hizmet almaya yönlendirebilirsiniz." />

                    
          
          <div
            className={styles.paraxImg}
            style={{
              background: `url(${webdesignFoto}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
        </div>

        <div className={styles.right}>
          <h3>Son Paylaşımlar</h3>

          <Link to={allCards[0].link} target="_blank">
            <strong>{allCards[0].title}</strong>
          </Link>
          <Link to={allCards[1].link} target="_blank">
            <strong>{allCards[1].title}</strong>
          </Link>
          <Link to={allCards[2].link} target="_blank">
            <strong>{allCards[2].title}</strong>
          </Link>
          <Link to={allCards[3].link} target="_blank">
            <strong>{allCards[3].title}</strong>
          </Link>
          {/* <Link to="/ankara-web-tasarim-firmalari">
            Profesyonel Web Tasarım{" "}
            {townsOfInterest[0].charAt(0).toUpperCase() +
              townsOfInterest[0].slice(1)}
          </Link> */}
        </div>
      </section>

      <section className={styles.benzerYazilar}>
        <h3>İlgili Makaleler</h3>
        <div className={styles.cardsContainer}>{CardsManualfromData}</div>
      </section>

      <section className={styles.bottomTags}>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[0].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[1].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[2].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web site</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>kurumsal web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>profesyonel web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web yazılım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>website hazırlama</strong>
        </Link>
      </section>

      <Footer />
    </div>
  )
}

export default SeoNedir
