import React, { useEffect } from "react"
import styles from "./WebsitesiFiyatlari.module.scss"
import { Link, useLocation } from "react-router-dom"
import webdesignFoto from "../../../assets/images/mobil-uyumlu-web-tasarim.png"
import haritaTurkey from "../../../assets/images/haritaTurkey.png"
import {
  townsEnglish,
  cardsManual
} from "../../../assets/data/TownsData"
import { Seo } from "../../../components/seo/Seo"
import Footer from "../../../components/footer/Footer"
import { Heading, TableOfContent } from "../../../components/customhooks/useHeadings"
import Card from "../../../components/card/Card"

const WebsitesiFiyatlari = ({title, imgName}) => {
  const allCards = cardsManual

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [])

  // eslint-disable-next-line no-extend-native
  Array.prototype.shuffle = function() {
    let m = this.length, i;
    while (m) {
      i = (Math.random() * m--) >>> 0;
      [this[m], this[i]] = [this[i], this[m]]
    }
    return this;
  }

  const CardComp = ({imgName, title, link }) => (
    <Card key={link} cardClass={`${styles.card} webDesignCard`}>
        <div className={styles.imgContainer} key={title}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
        </div>
        <h2><strong>{title}</strong></h2>
        <Link to={`${link}`} state={{imageUrl: `/images/${imgName}`, title: title}} onClick={()=>window.scrollTo({ top: 0, behavior: "smooth" })}>Devamı ⯈</Link>
    </Card>
)


const CardsManualfromData = cardsManual.filter((card)=> card.title !== title).shuffle().slice(0, 6).map((card, i)=>(
    <CardComp key={card.key} imgName={card.imgName} title={card.title} link={card.link} />
))

  return (
    <div className={styles.container}>
      <Seo
        title={`${title} | Web Tasarım | Bubi Yazılım `}
        description="Siz hayal edin, biz yapalım.."
        keywords="Web sitesi fiyatları, 2023 yılı web sitesi fiyatı, web tasarım fiyatı"
      />
      <h1>{title}</h1>
      <section className={styles.splitSection}>
        <div className={styles.left}>
          <div className={styles.imgContainer}>
            <img src={`/images/${imgName}`} alt={imgName} loading="lazy" />
          </div>
          <p>
            “{title}" sorusuna tek bir kısa cevap verecek olursak; Web sitesi
            fiyatları, tasarım yaptırmaya karar verdiğinizde belirleyici
            faktördür. Web tasarımı yaptırmak isteyen kişi, kurum veya
            kuruluşlar doğal bir refleksle fiyatı en uygun ve en kaliteli
            hizmeti satın almak ister. <br />
            <br /> Bu durum, ürün veya hizmet satın alanların gerçekçi
            beklentisidir. Aklınızda belirlediğiniz bir fiyat aralığı olsa da,
            projenizin kapsamı doğrultusunda maliyetler değişecektir. <br />{" "}
            <br />
            “Zaman, kolay elde edilen ve ucuz olan şeyleri siler.” Roy Chansior{" "}
            <br />
            <br />
            Google’da fiyat araştırması yaptığınızda çok farklı fiyatlar
            bulabilirsiniz. Fiyat dengesizliğinin nedenini öğrenmek
            istiyorsanız, sizler için hazırladığımız makaleyi okumanızı tavsiye
            ederiz.
            <a href="https://bubiyazilim.com" target="_blank" rel="noreferrer">
              Bubi Yazılım Web Tasarım ve Mobil Uygulama Hizmetleri
            </a>{" "}
            olarak web tasarımı konusunda tecrübemizi, işletmelerin dijital
            dünyada yer alması için özveriyle aktarıyoruz.
          </p>

          <TableOfContent />

          <Heading as="h2">
            Web Sitesi Yaptırmadan Önce Kendinize Soracağınız 3 Soru
          </Heading>
          
          <div className={styles.imgContainer}>
            <img src={`/images/question.jpg`} alt={imgName} loading="lazy" />
          </div>
          <p>
            Tasarım firmalarıyla ilgili araştırma yapmaya başladığınızda fiyat
            ve firmalar hakkında daha çok bilgiye sahip olursunuz. “Fiyatı ne
            kadar?” şeklinde başlayan ilk sorunuza yanıt ararken, kendinize
            sormanız gereken 3 önemli soru vardır:
          </p>
          <p>
            <br />
            1-) Ödediğim ücretin karşılığında beklentilerimi karşılayan bir
            siteye sahip olacak mıyım? <br />
            <br />
            2-) Sitenin güncelliğini koruması için destek almaya devam edecek
            miyim? <br />
            <br />
            3-) Beklenmeyen bir sorun yaşarsam, firmayla hızlı bir şekilde
            iletişim kurup sorunu çözebilecek miyiz?
            <br />
            <br />
          </p>
          <p>
            Soruları neden tasarım firmasına sormuyoruz, kendimize soruyoruz?
            Hangi firmaya veya freelance tasarımcıya sorarsanız sorun, cevap
            aynıdır. Kuşkusuz, hiçbir firma ve tasarımcı sorulara aşağıdaki
            şekilde cevap vermez. <br />
            <br />
            1-) “Ödediğiniz ücrete göre kalitesiz bir çalışma yapacağım. Bununla
            idare edin.” <br />
            <br />
            2-) “Siteniz yayınlandıktan sonra benim işim biter, aramazsanız
            sevinirim.” <br />
            <br />
            3-) "Sitenizle ilgili sorun yaşarsanız kendiniz halledin. Biz müsait
            olursak size geri dönüş yaparız.” <br />
            <br />
            Elbette cevaplar abartılı görünüyor. Ancak projeniz bittikten sonra
            firmayla iletişim kurmak istediğinizde telefonlarınıza zamanında ve
            düzgün bir şekilde yanıt veren bir firma bulamazsanız pişmanlık ve
            çaresizlik duygusu hissetmeniz kaçınılmazdır. <br />
            <br />
            Ödediğiniz ücretin karşılığını verecek kaliteli hizmetler, sitenizin
            yayınlanması sonrası alacağınız teknik destek ve herhangi bir sorun
            yaşadığınızda sunulan hızlı ve etkili çözümler web sitesi
            fiyatlarını belirleyen en önemli konuların başında gelir. Sizlere
            adı geçen hizmetleri sunan kaliteli bir firmanın fiyat politikasıyla
            özensiz ve ilgisiz çalışmalar yapan bir firmanın sizlere sunacağı
            fiyat teklifi kesinlikle aynı olmayacaktır.
          </p>
          
          <Heading as="h2">Web Sitesi Fiyatları Neye Göre Belirlenir?</Heading>
          

          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/${imgName}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            Web sitesi fiyatları belirlenirken birçok kriter göz önünde
            bulundurulur. Ancak burada anlatılacak maddeler ne tek başına ne de
            her zaman geçerli olmayabilir. Aşağıdaki bölümde firmadan firmaya
            farklılık gösterecek temel konulara yer verdik.
          </p>
          <Heading as="h3">Konum</Heading>
          <p>
            Hizmet alacağınız firmanın bulunduğu konumun önemi seçtiğiniz
            iletişim yöntemine bağlı olarak değişiklik gösterir. Şehirlerarası
            yolculuk ve konaklama gerektirecek projelerde yol ve konaklama
            masrafları fiyatlara yansıtılabilir. Aynı şehirde hizmet verilecek
            ancak çoklu ziyaret gerektiren uzak mesafeler web sitesi fiyatını
            etkiler. Günümüzde şehir dışı projelerde genellikle telefon
            görüşmeleriyle projeler detaylandırılır. İçerikler WeTransfer gibi
            çeşitli dosya transfer uygulamaları veya e-posta servisleri
            aracılığıyla gönderilir. Bahsedilen çalışma şekline sahip projelerde
            firmanın bulunduğu konumun web sitesi fiyatlarına bir etkisi yoktur.
          </p>
          <Heading as="h3">Çalışan Sayısı</Heading>
          <p>
            Firma çalışan sayısı fazla olan bir şirketin giderleri de doğru
            orantılı olarak artar. Firmanın sağlıklı bir şekilde sektörde
            ayakları üstünde kalması benimsemiş olduğu fiyat politikalarıyla
            büyük ölçüde ilişkilidir. Bununla ilgili bir örnek verelim. Objektif
            bir değerlendirmede gerçek değeri 3000 TL + KDV olan bir projeyi ele
            alalım. Az çalışanı olan bir firmanın veya freelance olarak hizmet
            veren bir tasarımcının verilen fiyat üzerinden yapabileceği indirim
            büyük ölçekli bir firmaya göre daha fazla olabilir. Fiyat
            politikaları ve şirket içi dengelere göre yapılacak indirimlerin
            oranları firmadan firmaya değişiklik gösterebilir.
          </p>
          <Heading as="h3">Yazılım Teknolojisi</Heading>
          
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/yazilim.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            Hazırlık sürecinde tasarımcıların kullandığı yazılım teknolojisi ve
            teknikler kaliteyi büyük ölçüde etkiler. Sayfalar kodlanırken HTML,
            CSS, JavaScript ve PHP gibi programlama dilleri kullanılır. Grafik
            tasarım çalışmaları sırasında günümüzde yaygın olarak tüm dünyada
            popülerliğini kanıtlamış pixel tabanlı Photoshop yazılımı tercih
            edilir. Programlama dillerini ve yazılımları her tasarımcı kendi
            yetenekleri ölçüsünde kullanarak siteleri tasarlar ve temalar
            geliştirir. Tasarımların tümü mobil uyumlu (responsive) olmalıdır.
            Bununla beraber, yönetilebilirliğini arttırmak için Yönetim Paneli
            yazılımları geliştirerek projelere entegre eder. Dolayısıyla
            tasarımcının proje altyapısında kullandığı tüm nitelikler web
            sitelerindeki kaliteyi belirler. Kalite web sitesi fiyatını
            belirleyici önemli bir faktördür.
          </p>
          <Heading as="h3">Hizmet Kalitesi</Heading>
          <div
            className={styles.paraxImg}
            // ref={paraxImgRef}
            style={{
              background: `url(/images/callcenter.jpg) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
          <p>
            Verilen hizmetler ve hizmet kalitesi farklılık gösterebilir. Bazı
            tasarım firmaları web tasarım hizmetlerine ek olarak kurumsal kimlik
            çalışmaları kapsamında hizmet verirken bazı firmalar sadece tasarım
            hizmeti sunabilmektedir. Bu, firmaların şirket politikasıyla
            ilgilidir. Firmalar hizmetlerini kendi firma kapasitesi ve
            potansiyelleri ölçüsünde verir. “Her işi herkes yapar” şeklinde tek
            bir doğru yoktur. Kurumsal kimlik çalışmaları yapılmamış ve siteye
            ihtiyacı olan bir firmanın, her iki hizmeti de tek bir yerden alması
            geçerli bir neden olsa bile bir zorunluluk değildir. Günümüzde web
            tasarım ihtiyaçları beraberinde farklı hizmetlere olan
            gereksinimleri doğurmuştur. Kurum veya kuruluşlar aynı zamanda Arama
            Motoru Optimizasyonu (SEO), Sosyal Medya Danışmanlığı veya Dijital
            Pazarlama alanlarında çok çeşitli hizmetlerden yararlanmaya
            başlamışlardır. Saydığımız hizmetlerin her birisi uzmanlık
            gerektirirken, içine girdiğinizde bir ağacın dalları gibi bünyesinde
            çok fazla sayıda özellik ve yönetim tekniği içerir.
          </p>
          <Heading as="h3">Web Sitesinin Türü</Heading>
          <p>
            Web siteleri, kurumsal tanıtım amaçlı yapılabildiği gibi ticari ürün
            satışları için de tercih edilebilir. E-ticaret siteleri, internet
            kanalıyla ticari ürün satışları için son yıllarda bir çıkış trendi
            yakalamıştır. Yoğun içerik barındıran E-ticaret siteleri güçlü bir
            altyapı üzerine kurulmalıdır. Ürün içeriklerinin oluşturulması ve
            entegrasyonu titiz bir çalışmayı gerektirir. Bu saydığımız unsurlar
            fiyatı arttırır. Tek sayfa olarak tasarlanan projelerde maliyetler
            oldukça düşüktür. Ana sayfa üzerinde firma ve hizmetleriyle ilgili
            detaylara kısa kısa yer verilir. Günümüzde çok fazla tercih
            edilmemektedir. Kurumsal tanıtım amaçlı siteler sıklıkla tercih
            edilen web tasarım projeleridir. Firmaların ürün ve hizmetleriyle
            ilgili tanıtım sayfaları oluşturulur. Harita, video ve animasyonlar
            yayınlanabilir. İletişim formu, teklif talep formu veya insan
            kaynakları formu kullanılarak site ziyaretçileriyle etkili bir
            iletişim kurulabilir. Projelerde sitede barındırılacak içerik
            miktarı ve web tasarım çalışmalarında yapılacak işin yoğunluğu web
            sitesi fiyatını belirler.
          </p>
          <Heading as="h3">Yönetim Paneli Desteği</Heading>
          <p>
            Web tasarım firmaları web sitelerinin sahipleri tarafından
            güncellenebilmesi adına yönetim paneli geliştirmiştir. Yönetim
            panelleri web sitenize çok sayıda resim, metin, blog makalesi veya
            video eklemenizi sağlayan gelişmiş yazılımlardır. Bu yazılımlar
            müşterilere ücretsiz sunulabildiği gibi, ek ücret talep eden
            firmalar da olabilir.
          </p>
          <Heading as="h3">Hazır Web Sitesi</Heading>
          <p>
            Son yıllarda “hazır web sitesi” kavramı ortaya çıkmıştır. Hazır
            scriptler kullanılarak hazırlanan web sitelerinde maliyetler oldukça
            düşüktür. Burada dikkat etmeniz gereken konu alacağınız hizmetin
            kalitesidir. 1000 TL, 1500 TL gibi düşük fiyatları olan sistemlerle
            site sahibi olabilirsiniz. Ancak benzer sistemlerden kurumsal web
            tasarım hizmetleri beklemeniz sizi hayal kırıklığına uğratabilir.
            Sonuçta dikkatli ve titiz bir çalışma yaparak firmanızı
            seçmelisiniz.
          </p>
          <Heading as="h2">Çalışacağım Firmayı Nasıl Seçmeliyim?</Heading>
          <p><br /><br />
            1-) Şirketin web sitesini ziyaret ederek daha önce yapmış olduğu
            referans çalışmaları inceleyin. <br /><br />
            Web tasarım çalışmalarınızı
            yaptıracağınız firmayı seçmeden önce firma web sitesinde referanslar
            sayfasını inceleyebilirsiniz. Referanslar sizlere oldukça fazla
            fikir verecektir. Firmanın yapmış olduğu siteleri beğenmediğiniz
            takdirde farklı firma alternatiflerine yönelebilirsiniz. <br /><br />
            2-) Referans web sitesi sahipleriyle iletişime geçerek firma hakkında bilgi alın.<br /><br />
            Çalışmalarını beğendiğiniz bir firmanın proje teslim edildikten
            sonra sizlere destek olup olmayacağını referans web sitelerinin
            firma yetkililerini arayarak rahatlıkla öğrenebilirsiniz. Ayrıca
            müşteri görüşleri sayfasını inceleyerek firma hakkında fikir sahibi
            olabilirsiniz. Çalışmalarını beğendiğiniz bir web tasarım firması
            için olumlu yorum almışsanız içiniz rahat olmalı. Çok büyük
            ihtimalle firma beklentilerinizi karşılayacaktır. <br /><br />
            3-) Firmanın yaptığı çalışmaları beğendiniz. Referanslarından bilgi aldınız.
            Yeterli mi? <br /><br />
            Aslına bakacak olursanız firmayla çalışmaya
            başlayabilirsiniz. Ama yine de tasarımı yaptıracağınız firma
            yetkilisiyle kapsamlı görüşme yapmanız ve görüşmede projenizi
            detaylandırmanız gerekir. Web sitenizle ilgili beklentilerinizi tüm
            detaylarıyla görüşün. Sitenizde olmasını istediğiniz görsel ve
            teknik özellikleri mutlaka belirtin. Sizin için vazgeçilmez bir
            özelliği veya hizmeti web tasarım çalışmalarına başladıktan bir süre
            sonra dile getirdiğinizde ve firmanın aradığınız hizmeti vermediğini
            öğrendiğinizde kurmuş olduğunuz güzel iletişim zarar görebilir. Bu
            nedenle proje detayları tümüyle projeye başlamadan önce
            görüşülmelidir.
          </p>
          
          <Heading as="h2">Projenizi Takip Eden Firmalarla Çalışın</Heading>
          <p>Çalışmalar süresince etkin proje takibi yapan firmalarla çalışın. İçeriklerin zamanında tasarımcılara ulaşmasını sağlayın. Proje aşamasında içerikler site sahibi tarafından hazırlanmamış olabilir. Yavaş davrandığınızda yetkililer sizi sürekli arıyor, ilgileniyor ve yardımcı olmaya çalışıyorsa ilgili bir firmayla çalışıyorsunuz demektir. Web tasarım çalışması bir ekip çalışmasıdır. İçerikleri zamanında ve düzenli bir şekilde firmaya ulaştırarak projenin sağlıklı ve etkin bir şekilde hazırlanmasına katkıda bulunun.
          </p>
          <Heading as="h2">İçerik Kalitesini Arttırın</Heading>
          <p>Web tasarım firmasının kaliteli bir proje ortaya çıkarmasında site sahiplerinin önemli rolü vardır. Tasarım kadar içerik kalitesi de hayati önem taşır. Projenizde firmanızı en doğru şekilde ifade edecek kaliteli resim ve metinler kullanmanız etkili olacaktır. Özellikle metin üretmek konusunda sıkıntı yaşıyorsanız, profesyonel destek alarak sitenizi zenginleştirecek metinler üretebilirsiniz. Günümüzde içerik üreten seçkin makale yazarlarıyla iletişime geçerek kaliteli metinler yazdırabilirsiniz.
          </p>

          <div
            className={styles.paraxImg}
            style={{
              background: `url(${webdesignFoto}) no-repeat center center fixed`,
              backgroundSize:"cover"
            }}
          ></div>
        </div>

        <div className={styles.right}>
          <h3>Son Paylaşımlar</h3>

          <Link to={allCards[0].link} target="_blank">
            <strong>{allCards[0].title}</strong>
          </Link>
          <Link to={allCards[1].link} target="_blank">
            <strong>{allCards[1].title}</strong>
          </Link>
          <Link to={allCards[2].link} target="_blank">
            <strong>{allCards[2].title}</strong>
          </Link>
          <Link to={allCards[3].link} target="_blank">
            <strong>{allCards[3].title}</strong>
          </Link>
          {/* <Link to="/ankara-web-tasarim-firmalari">
            Profesyonel Web Tasarım{" "}
            {townsOfInterest[0].charAt(0).toUpperCase() +
              townsOfInterest[0].slice(1)}
          </Link> */}
        </div>
      </section>

      <section className={styles.benzerYazilar}>
        <h3>İlgili Makaleler</h3>
        <div className={styles.cardsContainer}>{CardsManualfromData}</div>
      </section>

      <section className={styles.bottomTags}>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[0].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[1].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>{cardsManual[2].title}</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web sitesi fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web site</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>kurumsal web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>profesyonel web site tasarımı</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web yazılım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım fiyatları</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>web tasarım</strong>
        </Link>
        <Link to="https://bubiyazilim.com" target="_blank">
          <strong>website hazırlama</strong>
        </Link>
      </section>

      {/* <Footer /> */}
    </div>
  )
}

export default WebsitesiFiyatlari
