import React, { useCallback, useEffect, useRef, useState } from "react"
import styles from "./MainSlider.module.scss"
import Card from "../card/Card"
import StarsRating from "react-star-rate"
import useEmblaCarousel from "embla-carousel-react"
import Autoplay from "embla-carousel-autoplay"
import { DotButton } from "../carousel/EmblaCarouselArrowsDotsButtons"
import perde1 from "../../assets/images/perde1.webp"
import perde2 from "../../assets/images/perde2.jpg"
import perde3 from "../../assets/images/perde3.jpg"
import perde4 from "../../assets/images/perde4.jpg"

const MainSlider = ({carouselReady}) => {
  const autoplay = useRef(
    Autoplay(
      { delay: 2000, stopOnInteraction: false },
      (emblaRoot) => emblaRoot.parentElement
    )
  )
  // loop: true yapınca ilk slide'da ufak bi bozukluk oluyor autoplayde
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: false }, [
    autoplay.current
  ])
  const [scrollSnaps, setScrollSnaps] = useState([])
  const [selectedIndex, setSelectedIndex] = useState(0)
  // const [carouselReady, setCarouselReady] = useState(false)
  const scrollTo = useCallback(
    (index) => emblaApi && emblaApi.scrollTo(index),
    [emblaApi]
  )

  const onSelect = useCallback(() => {
    if (!emblaApi) return
    setSelectedIndex(emblaApi.selectedScrollSnap())
    // setPrevBtnEnabled(emblaApi.canScrollPrev())
    // setNextBtnEnabled(emblaApi.canScrollNext())
    // xxx.current.style.cssText = `width: 100%;`
  }, [emblaApi, setSelectedIndex])

  useEffect(() => {
    if (!emblaApi) return
    // onSelect()
    setScrollSnaps(emblaApi.scrollSnapList())
    emblaApi.on("select", onSelect)
    emblaApi.on("reInit", onSelect)
  }, [emblaApi, setScrollSnaps, onSelect])

  const xxx = useRef(null)

  useEffect(() => {
    emblaApi?.reInit()
    // xxx.current.style.cssText = `width: 99%;`
  }, [emblaApi])

  const onImgLoad = () => {
    // setCarouselReady(true)
  }
  
  
  return (
    <div className={styles.sliderContainer}>
      <h1>Çağlayan Stor,<br />Perde, Mefruşat, Korniş Hizmetleri </h1>
      
      <div className={styles.embla} ref={xxx}>
      {carouselReady &&
        <div className={styles.embla__viewport} ref={carouselReady ? emblaRef : null}>
          <div className={styles.embla__container}>
            <div className={`${styles.embla__slide} xx`}>
              <div className={styles.embla__slide__number}>
                <span>1</span>
              </div>
              <img
                className={styles.embla__slide__img}
                // src={source[index]}
                src={perde1}
                alt="Vitrin resmi"
                onLoad={onImgLoad}
                // style={imgLoaded ? {filter:"none"} : {filter:"blur(10px)"}}
              />
            </div>

            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__number}>
                <span>1</span>
              </div>
              <img
                className={styles.embla__slide__img}
                // src={source[index]}
                src={perde2}
                alt="Vitrin resmi"
                // onLoad={onImgLoad}
                // style={imgLoaded ? {filter:"none"} : {filter:"blur(10px)"}}
              />
            </div>

            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__number}>
                <span>1</span>
              </div>
              <img
                className={styles.embla__slide__img}
                // src={source[index]}
                src={perde3}
                alt="Vitrin resmi"
                // onLoad={onImgLoad}
                // style={imgLoaded ? {filter:"none"} : {filter:"blur(10px)"}}
              />
            </div>

            <div className={styles.embla__slide}>
              <div className={styles.embla__slide__number}>
                <span>1</span>
              </div>
              <img
                className={styles.embla__slide__img}
                // src={source[index]}
                src={perde4}
                alt="Vitrin resmi"
                // onLoad={onImgLoad}
                // style={imgLoaded ? {filter:"none"} : {filter:"blur(10px)"}}
              />
            </div>

          </div>

          <div className={styles.embla__dots}>
            {scrollSnaps.map((_, index) => (
              <DotButton
                key={index}
                selected={index === selectedIndex}
                onClick={() => scrollTo(index)}
              />
            ))}
          </div>
        </div>
      }
      </div>
    </div>
  )
}

export default MainSlider
