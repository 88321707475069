import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstRender: true,
  cardTowns: [],
  // cardsAll: localStorage.getItem("cardsAll") ? JSON.parse(localStorage.getItem("cardsAll")) : null
  cardsAll: null,
  currentPage: 1
};

const navSlice = createSlice({
  name: "nav",
  initialState,
  reducers: {
    SET_FIRST_RENDER: (state, action) => {
      state.firstRender = action.payload
    },
    SET_cardTowns: (state, action) => {
      state.cardTowns = action.payload
    },
    SET_cardsAll: (state, action) => {
      state.cardsAll = action.payload
      // console.log("action.payload",action.payload)
      // localStorage.setItem("cardsAll", JSON.stringify(state.cardsAll.toString()))
    },
    SET_CURRENTPAGE: (state, action) => {
      state.currentPage = action.payload
    },
  },
});

export const { SET_FIRST_RENDER, SET_cardTowns, SET_cardsAll, SET_CURRENTPAGE } = navSlice.actions;

export const selectFirstRender = (state) => state.nav.firstRender;
export const selectCardTowns = (state) => state.nav.cardTowns;
export const selectCardsAll = (state) => state.nav.cardsAll;
export const selectCurrentPage = (state) => state.nav.currentPage;

export default navSlice.reducer;